import { Badge, IconButton, Tooltip } from '@mui/material';
import { injectIntl } from 'react-intl';
import { BaseComponent } from '../../baseComponent';
import { ConnectedIcon, DisconnectedIcon, UnhealthIcon } from '../../icons';
import { SystemHealth } from '../types';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';

export interface SystemHealthButtonProps {
  intl: any;
  systemHealth: SystemHealth | null;
  services: ServiceStatus[];
  onClick: (event: React.BaseSyntheticEvent) => void;
  healthyColor?: any;
  unhealthyColor?: any;
  companyConnectionErrorColor?: any;
  pureCertificate?: any;
  size?: 'small' | 'large' | 'medium';
  iconFontSize?: 'small' | 'inherit' | 'large' | 'medium';
  theme: any; 
}

export interface ServiceStatus {
  title: string;
  regionId: number | null;
  isConnected: boolean;
  isHealthy: boolean;
  message: string | null;
  lastUpdateDate: string;
}

interface SystemHealthButtonState {}

class SystemHealthButton extends BaseComponent<SystemHealthButtonProps, SystemHealthButtonState> {
  pureCertificate: any;

  unhealthIconStyle: any;
  connectedIconOrangeStyle: any;
  connectedIconStyle: any;

  constructor(props: SystemHealthButtonProps) {
    super(props);
    this.pureCertificate = this.getUserProfileData('CertificateInfo');

    this.computeStyles(props);
  }

  componentDidUpdate(prevProps: SystemHealthButtonProps) {
    if (
      prevProps.systemHealth !== this.props.systemHealth ||
      prevProps.unhealthyColor !== this.props.unhealthyColor ||
      prevProps.companyConnectionErrorColor !== this.props.companyConnectionErrorColor ||
      prevProps.healthyColor !== this.props.healthyColor ||
      prevProps.theme !== this.props.theme
    ) {
      this.computeStyles(this.props);
    }
  }

  computeStyles(props: SystemHealthButtonProps) {
    const { theme } = props;

    this.unhealthIconStyle = {
      color: !props.systemHealth
        ? theme.palette.grey
        : props.unhealthyColor ?? theme.palette.error.main,
    };

    this.connectedIconOrangeStyle = {
      color: 'orange',
    };

    let rejectedCompanyCount = 0;
    props.systemHealth?.services.forEach(
      (s) => (rejectedCompanyCount += s.companyConnections?.rejectedCompanies.length ?? 0)
    );

    this.connectedIconStyle = {
      color:
        (rejectedCompanyCount > 0
          ? props.companyConnectionErrorColor ?? theme.palette.text.secondary
          : props.healthyColor) ?? theme.palette.primary.main,
    };
  }

  render() {
    const { formatMessage } = this.props.intl;

    let rejectedCompanyCount = 0;
    const omieService = this.props.systemHealth?.services.find((x) => x.title === 'Omie');
    const isOmie = !!omieService;

    const certificateIsConnected =
      (omieService?.companyConnections?.connectedCompanies?.length === 0 &&
        omieService?.companyConnections?.rejectedCompanies?.length > 0) ||
      this.pureCertificate == null
        ? false
        : true;

    this.props.systemHealth?.services.forEach(
      (s) => (rejectedCompanyCount += s.companyConnections?.rejectedCompanies.length ?? 0)
    );

    let badge =
      (!certificateIsConnected && isOmie) || (this.pureCertificate == null && isOmie)
        ? '!'
        : rejectedCompanyCount;

    return (
      <Tooltip title={formatMessage({ id: 'global.connection' })}>
        <IconButton
          component="div" // Disabled child uyarısını önlemek için
          size={this.props.size ?? 'medium'}
          onClick={this.props.onClick}
          disabled={!Boolean(this.props.systemHealth)}
        >
          <Badge
            invisible={rejectedCompanyCount === 0 && this.pureCertificate != null}
            badgeContent={badge}
            color="error"
          >
            {!this.props.systemHealth?.isSystemHealthy ? (
              <UnhealthIcon
                fontSize={this.props.iconFontSize}
                sx={this.unhealthIconStyle}
              />
            ) : !certificateIsConnected && isOmie ? (
              <ConnectedIcon
                fontSize={this.props.iconFontSize}
                sx={this.connectedIconOrangeStyle}
              />
            ) : (
              <ConnectedIcon
                fontSize={this.props.iconFontSize}
                sx={this.connectedIconStyle}
              />
            )}
          </Badge>
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    systemHealth: state.systemhealth.systemHealth,
  };
};

export default injectIntl(connect(mapStateToProps)(withTheme(SystemHealthButton)));
