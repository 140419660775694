import equal from "fast-deep-equal";
import { createSelector } from "reselect";
import { Middleware } from "redux";
import {
   IGipPlanningTickerInfo,
   INamedDepthInfo,
   INamedMyProposal,
   INamedContractDetail,
   INamedRelatedGroupProposal,
   IDepthInfo,
} from "../../api/types/gipPlanningWebSocketTypes";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import { GipPlanningActionKeys } from "./gipPlanningActionKeys";
import { EProposalType } from "src/enums/proposal-type.enum";
import { IDepthProposalData } from "src/models/gip-planning/DepthWidgetModels";

const tickerLimit = 250;
const BATCH_UPDATE_DEPTH_MIDDLEWARE_THROTTLE_LIMIT = 1000;
const BATCH_UPDATE_MYPROPOSALS_MIDDLEWARE_THROTTLE_LIMIT = 1000;
const BATCH_UPDATE_RELATED_GROUP_PROPOSALS_MIDDLEWARE_THROTTLE_LIMIT = 1000;
const BATCH_UPDATE_TRADES_MIDDLEWARE_THROTTLE_LIMIT = 1000;
const defaultTickerValue: IGipPlanningTickerInfo[] = [];
const defaultDepthValue: INamedDepthInfo = {};
const defaultMyProposalsValue: INamedMyProposal = {};
const defaultTradesValue: INamedContractDetail = {};
const defaultRelatedGroupProposalValue: INamedRelatedGroupProposal = {};
const lastUpdateDepthActions: { [contractDataKey: string]: PayloadAction<CommonAction> } = {};
let depthActionsBatchInterval: NodeJS.Timeout | null = null;
interface CommonAction {
   contractDataKey: string;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   data: any;
}

type MiddlewareKeys = {
   update: string;
   startInterval: string;
   clearInterval: string;
};

const createBatchUpdateMiddleware = (keys: MiddlewareKeys, throttleLimit: number): Middleware => {
   let lastUpdateActions: { [contractDataKey: string]: PayloadAction<CommonAction> } = {};
   let batchInterval: NodeJS.Timeout | null = null;

   return ({ dispatch }) =>
      (next) =>
      (action) => {
         const applyUpdates = () => {
            const existingKeys = Object.keys(lastUpdateActions);
            if (existingKeys.length > 0) {
               existingKeys.forEach((key) => {
                  if (lastUpdateActions[key]) {
                     next(lastUpdateActions[key]);
                     delete lastUpdateActions[key];
                  }
               });
            }
         };

         if (action.type === keys.update) {
            lastUpdateActions[action.payload.contractDataKey] = action;
            return;
         } else if (action.type === keys.startInterval && batchInterval == null) {
            batchInterval = setInterval(applyUpdates, throttleLimit);
            return;
         } else if (action.type === keys.clearInterval && batchInterval) {
            clearInterval(batchInterval);
            batchInterval = null;
            return;
         } else {
            return next(action);
         }
      };
};

export const batchUpdateDepthMiddleware: Middleware = createBatchUpdateMiddleware(
   {
      update: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_UPDATE,
      startInterval: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_START_INTERVAL,
      clearInterval: GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_CLEAR_INTERVAL,
   },
   BATCH_UPDATE_DEPTH_MIDDLEWARE_THROTTLE_LIMIT
);

export const batchMyProposalUpdaterMiddleware: Middleware = createBatchUpdateMiddleware(
   {
      update: GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_UPDATE,
      startInterval: GipPlanningActionKeys.GIP_PLANNING_MYPROPOSAL_INFO_START_INTERVAL,
      clearInterval: GipPlanningActionKeys.GIP_PLANNING_MYPROPOSAL_CLEAR_INTERVAL,
   },
   BATCH_UPDATE_MYPROPOSALS_MIDDLEWARE_THROTTLE_LIMIT
);

export const batchRelatedGroupProposalUpdaterMiddleware: Middleware = createBatchUpdateMiddleware(
   {
      update: GipPlanningActionKeys.GIP_PLANNING_RELRATED_GROUP_PROPOSALS_UPDATE,
      startInterval: GipPlanningActionKeys.GIP_PLANNING_RELATED_GROUP_PROPOSALS_START_INTERVAL,
      clearInterval: GipPlanningActionKeys.GIP_PLANNING_RELATED_GROUP_PROPOSALS_CLEAR_INTERVAL,
   },
   BATCH_UPDATE_RELATED_GROUP_PROPOSALS_MIDDLEWARE_THROTTLE_LIMIT
);

export const batchTradesUpdaterMiddleware: Middleware = createBatchUpdateMiddleware(
   {
      update: GipPlanningActionKeys.GIP_PLANNING_TRADES_UPDATE,
      startInterval: GipPlanningActionKeys.GIP_PLANNING_TRADES_START_INTERVAL,
      clearInterval: GipPlanningActionKeys.GIP_PLANNING_TRADES_CLEAR_INTERVAL,
   },
   BATCH_UPDATE_TRADES_MIDDLEWARE_THROTTLE_LIMIT
);

export const gipPlanningTickersReducer = (state = defaultTickerValue, action) => {
   switch (action.type) {
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD:
         return [action.payload, ...state.slice(0, tickerLimit)];
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_ADD_ARRAY:
         return [...action.payload, ...state.slice(0, tickerLimit)];
      case GipPlanningActionKeys.GIP_PLANNING_TICKER_CLEAR:
         return [];
      default:
         return state;
   }
};

export const gipPlanningDepthInfoReducer = (
   state = defaultDepthValue,
   action: PayloadAction<CommonAction>
): INamedDepthInfo => {
   switch (action.type) {
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_UPDATE: {
         const { contractDataKey, data } = action.payload;
         if (!state[contractDataKey]) {
            state[contractDataKey] = {
               buyDepths: [],
               sellDepths: [],
               depthSummary: {
                  maxBuyCumulativeAmount: 0,
                  maxSellCumulativeAmount: 0,
                  maxCumulativeAmount: 0,
                  buyDepthCount: 0,
                  sellDepthCount: 0,
               },
            };
         }

         const partDataList = _.partition(data.depths, (x) => x.proposalType === EProposalType.Buy);
         for (let i = 0; i < partDataList.length; i++) {
            let partKey = "";
            if (i === 0) {
               partKey = "buyDepths";
            } else if (i === 1) {
               partKey = "sellDepths";
            }
            if (partKey === "") continue;
            const partData = partDataList[i];
            state[contractDataKey][partKey] = [...partData];
         }
         state[contractDataKey].depthSummary = {
            ...state[contractDataKey].depthSummary,
            ...data.depthSummary,
         };
         return state;
      }
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_CLEAR:
         state = {} as INamedDepthInfo;
         return state;
         break;
      case GipPlanningActionKeys.GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR:
         if (!state[action.payload.contractDataKey]) return state;
         return _.omit(state, action.payload.contractDataKey);
         break;
      default:
         return state;
   }
};

export const gipPlanningMyProposalsReducer = (
   state = defaultMyProposalsValue,
   action: PayloadAction<CommonAction>
): INamedMyProposal => {
   switch (action.type) {
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_UPDATE:
         if (!equal(state[action.payload.contractDataKey], action.payload.data))
            return { ...state, [action.payload.contractDataKey]: [...action.payload.data] };
         else return state;
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_CLEAR:
         return {} as INamedMyProposal;
      case GipPlanningActionKeys.GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR:
         return _.omit(state, action.payload.contractDataKey);
      default:
         return state;
   }
};

export const gipPlanningRelatedGroupProposalsReducer = (
   state = defaultRelatedGroupProposalValue,
   action: PayloadAction<CommonAction>
): INamedRelatedGroupProposal => {
   switch (action.type) {
      case GipPlanningActionKeys.GIP_PLANNING_RELRATED_GROUP_PROPOSALS_UPDATE:
         if (!equal(state[action.payload.contractDataKey], action.payload.data))
            return { ...state, [action.payload.contractDataKey]: { ...action.payload.data } };
         else return state;
      case GipPlanningActionKeys.GIP_PLANNING_RELRATED_GROUP_PROPOSALS_CLEAR:
         return {} as INamedRelatedGroupProposal;
      case GipPlanningActionKeys.GIP_PLANNING_RELRATED_GROUP_PROPOSALS_FROM_KEY_CLEAR:
         return _.omit(state, action.payload.contractDataKey);
      default:
         return state;
   }
};

export const gipPlanningTradesReducer = (
   state = defaultTradesValue,
   action: PayloadAction<CommonAction>
): INamedContractDetail => {
   switch (action.type) {
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_UPDATE:
         return { ...state, [action.payload.contractDataKey]: [...action.payload.data] };
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_CLEAR:
         return {} as INamedContractDetail;
      case GipPlanningActionKeys.GIP_PLANNING_TRADES_FROM_KEY_CLEAR:
         return _.omit(state, action.payload.contractDataKey);
      default:
         return state;
   }
};

export const selectPagedDepthsWithCumulative = createSelector(
   [
      (state: IDepthInfo[]) => state || [],
      (_: IDepthInfo[], paginationParams: { page: number; pageSize: number }) => paginationParams,
   ],
   (depthList, paginationParams): IDepthProposalData[] => {
      if (!depthList || depthList.length == 0) return [];
      const { page, pageSize } = paginationParams;

      const cumulativeQuantityBeforePage = _.sumBy(depthList.slice(0, page * pageSize), "quantity");

      const startIndex = pageSize * page;
      const endIndex = startIndex + pageSize;
      const pagedDepths = depthList.slice(startIndex, endIndex);
      let cumulativeQuantity = cumulativeQuantityBeforePage;
      const pagedDepthsWithCumulative = pagedDepths.map((depth) => {
         cumulativeQuantity += depth.quantity;
         const depthData: IDepthProposalData = {
            amount: depth.quantity,
            price: depth.price,
            cumAmount: cumulativeQuantity,
            id: depth.id,
            type: depth.proposalType,
         };
         return depthData;
      });

      return pagedDepthsWithCumulative;
   }
);
