import equal from "fast-deep-equal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WidgetSettingsEnum } from "src/enums/widget-settings-enum";
import { uuidv4 } from "src/old/src/pages/intraday/constants";
import { SocketConnectionType } from "src/old/src/redux/api/types/gipPlanningWebSocketTypes";
import { StoreState } from "src/old/src/redux/reducers";
import {
   IContractSelectOption,
   onSelectedContractChange,
   setContractSelect,
} from "src/old/src/redux/slice/contractSelectData";
import { useGetMessagesQuery } from "src/old/src/redux/webSocket/gipPlanningWebSocket";
import useWidgetSettings from "./useWidgetSettings";
import _ from "lodash";
import { ActiveBoardWidgetInfo } from "src/old/src/redux/slice/workspaceData";
import { EContractType } from "src/enums/contract-type.enum";
import { getNextOpenContract } from "src/utils/intradayUtils";

export interface IUseSelectedContractProps {
   widgetId: string;
   disableBindDefaultValue?: boolean;
   disableSaveChanges?: boolean;
   defaultContractDataKey?: string;
}

export enum WidgetContractSourceTypeEnum {
   DEPEND_BOARD = 1,
   USE_OPEN_CONTRACT_INDEX,
}

export interface WidgetSelectOption {
   dependType: WidgetContractSourceTypeEnum;
   boardWidgetId?: string;
   openContractIndex: number;
   locked: boolean;
   contractKey: string;
   /**
    * If no value is given, the next first/next contract is selected regardless of the contract type.
    * If a contract type value is given, the first/next contract of that contract type is selected.
    */
   openContractChangeType?: EContractType;
}
const DEFAULT_WIDGET_SELECT_OPTION:WidgetSelectOption={
   dependType: WidgetContractSourceTypeEnum.DEPEND_BOARD,
   openContractIndex: 0,
   boardWidgetId: undefined,
   locked: false,
   contractKey: "",
}
const convertSavedValue = (value: WidgetSelectOption) => _.chain(value).omitBy(_.isNil).omit(["contractKey"]).value();

const useContractDetails = (props: IUseSelectedContractProps) => {
   const { widgetId } = props;
   const disableBindDefaultValue = props.disableBindDefaultValue ?? false;
   const disableSaveChanges = props.disableSaveChanges ?? false;
   const dispatch = useDispatch();

   const selectData = useSelector<StoreState.All, IContractSelectOption | undefined>(
      (state) => state.contractSelectData.contractSelectData[widgetId],
      equal
   );

   const boardWidgetIdList = useSelector<StoreState.All, ActiveBoardWidgetInfo[]>(
      (state) => state.workspaceData.activeBoardWidgetIdList,
      equal
   );

   const [beforeSelectedContract, saveNewContract] = useWidgetSettings<WidgetSelectOption>({
      defaultValue: DEFAULT_WIDGET_SELECT_OPTION,
      widgetId: props.widgetId,
      key: WidgetSettingsEnum.ContractSelectOptionKey,
      showPopUp: selectData?.useOpenContractIndex,
   });

   const [contract, setContract] = useState<string>("");
   const [wallId, setWallId] = useState<string>(beforeSelectedContract.boardWidgetId ?? "");
   const [wallDataKey, setWallDataKey] = useState<string>("_0_0");
   const [isLocked, setIsLocked] = useState(beforeSelectedContract.locked ?? false);
   const [showNoRowOverlay, setShowNoRowOverlay] = useState(false);

   const { contractList, allWallInfos } = useGetMessagesQuery(SocketConnectionType.ALL_CONTRACTS, {
      selectFromResult: (result) => ({
         contractList: result.data?.listedContractsInfo ?? [],
         allWallInfos: result.data?.selectedWall ?? {},
      }),
   });

   const openContracts = useMemo(() => contractList.filter((c) => c.isOpenForTrade), [contractList]);

   const getInstrument = () => {
      if (disableBindDefaultValue) return "";
      if (props.defaultContractDataKey) return props.defaultContractDataKey;
      if (beforeSelectedContract.dependType === WidgetContractSourceTypeEnum.DEPEND_BOARD) {
         if (beforeSelectedContract.contractKey) return beforeSelectedContract.contractKey;
         if (
            beforeSelectedContract.boardWidgetId &&
            boardWidgetIdList.find((x) => x.id === beforeSelectedContract.boardWidgetId)?.contract
         )
            return boardWidgetIdList.find((x) => x.id === beforeSelectedContract.boardWidgetId)?.contract ?? "";
         if (boardWidgetIdList.length > 0) {
            return boardWidgetIdList[0].contract ?? "";
         }
      }
      if (_.isUndefined(beforeSelectedContract.openContractChangeType))
         return (
            openContracts[beforeSelectedContract.openContractIndex]?.contractDataKey ??
            openContracts[0]?.contractDataKey ??
            ""
         );
      return (
         openContracts
            .filter((x) => x.contractType === beforeSelectedContract.openContractChangeType)
            .at(beforeSelectedContract.openContractIndex)?.contractDataKey ??
         openContracts[0]?.contractDataKey ??
         ""
      );
   };

   const getOpenContractIndex = () => {
      if (disableBindDefaultValue) return 0;
      if (beforeSelectedContract.dependType === WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX) {
         return beforeSelectedContract.openContractIndex ?? 0;
      }
      if (beforeSelectedContract.contractKey)
         return openContracts.findIndex((c) => c.contractDataKey === beforeSelectedContract.contractKey);
      return 0;
   };

   useEffect(() => {
      if (openContracts.length === 0 && contractList.length === 0) {
         const wallValues = Object.values(allWallInfos);
         // Hiçbir kontrat gelmemiştir
         // kontrat bulunamadı diye göstermek için
         if (wallValues.length > 0 && wallValues.every((c) => c.isLoaded && c.contractKeys.length === 0)) {
            setShowNoRowOverlay(true);
         }
         return;
      }

      if (!selectData && openContracts.length === 0 && contractList.length > 0 && boardWidgetIdList.length > 0) {
         dispatch(
            setContractSelect({
               widgetId,
               newData: {
                  _id: uuidv4(),
                  contractKey: boardWidgetIdList[0]?.contract ?? contractList[0].contractDataKey,
                  locked: false,
                  connectedBoardWidgetId: boardWidgetIdList[0].id,
                  openContractIndex: -1,
                  useOpenContractIndex: false,
               },
            })
         );
         return;
      }

      // for any contract not selected
      if (!selectData && openContracts.length > 0 && boardWidgetIdList.length > 0) {
         const connectedBoardWidgetId =
            boardWidgetIdList.find((board) => board.id === beforeSelectedContract.boardWidgetId)?.id ??
            boardWidgetIdList[0]?.id ??
            "";

         const addedInstrument = getInstrument();
         const openContractIndex = getOpenContractIndex();

         dispatch(
            setContractSelect({
               widgetId,
               newData: {
                  _id: uuidv4(),
                  contractKey: addedInstrument,
                  locked: beforeSelectedContract.locked,
                  connectedBoardWidgetId: connectedBoardWidgetId,
                  openContractIndex,
                  useOpenContractIndex: disableBindDefaultValue
                     ? false
                     : beforeSelectedContract.dependType === WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX,
                  openContractChangeType: beforeSelectedContract.openContractChangeType,
               },
            })
         );

         const _wallDataKey = `_${allWallInfos[connectedBoardWidgetId]?.companyId ?? 0}_${allWallInfos[connectedBoardWidgetId]?.portfolioId ?? 0}`;
         if (_wallDataKey !== wallDataKey) setWallDataKey(_wallDataKey);
         setWallId(connectedBoardWidgetId);
         setContract(addedInstrument);
      } // for change workspace or crate workspace and change connected board
      else if (
         selectData?.connectedBoardWidgetId &&
         boardWidgetIdList.length > 0 &&
         !boardWidgetIdList.some((board) => selectData?.connectedBoardWidgetId === board.id) &&
         openContracts.length > 0
      ) {
         dispatch(
            setContractSelect({
               widgetId,
               newData: {
                  _id: selectData._id ?? uuidv4(),
                  connectedBoardWidgetId: boardWidgetIdList[0].id,
                  contractKey: openContracts[0].contractDataKey,
                  openContractIndex: 0,
                  useOpenContractIndex: false,
                  locked: false,
               },
            })
         );
         setWallId(boardWidgetIdList[0].id);
         setContract(openContracts[0].contractDataKey);
         setWallDataKey(allWallInfos[boardWidgetIdList[0].id]?.selectedWallKey ?? "_0_0");
      }
   }, [openContracts, boardWidgetIdList, selectData]);

   const debouncedSaveFunction = _.debounce(
      (newOption: WidgetSelectOption) => {
         saveNewContract(newOption);
      },
      250,
      { trailing: true }
   );

   useEffect(() => {
      if (
         !selectData ||
         (selectData?.connectedBoardWidgetId === wallId &&
            !selectData.useOpenContractIndex &&
            selectData?.contractKey === contract &&
            selectData.locked === isLocked)
      ) {
         return;
      }

      if (selectData.connectedBoardWidgetId !== wallId) {
         setWallId(selectData.connectedBoardWidgetId);
      }

      const _wallDataKey = `_${allWallInfos[selectData.connectedBoardWidgetId]?.companyId ?? 0}_${allWallInfos[selectData.connectedBoardWidgetId]?.portfolioId ?? 0}`;
      if (_wallDataKey !== wallDataKey) setWallDataKey(_wallDataKey);

      if (selectData.contractKey !== contract) {
         setContract(selectData.contractKey);
      }

      if (selectData.locked !== isLocked) {
         setIsLocked(selectData.locked);
      }

      if (disableSaveChanges || openContracts.length === 0) return;

      const newValue: WidgetSelectOption = {
         boardWidgetId: selectData.connectedBoardWidgetId,
         openContractIndex: selectData.openContractIndex,
         dependType: selectData.useOpenContractIndex
            ? WidgetContractSourceTypeEnum.USE_OPEN_CONTRACT_INDEX
            : WidgetContractSourceTypeEnum.DEPEND_BOARD,
         locked: selectData.locked,
         contractKey: selectData.contractKey,
         openContractChangeType: selectData.openContractChangeType,
      };

      const newConvertedValue = convertSavedValue(newValue);
      const beforeValue = convertSavedValue(beforeSelectedContract);

      if (!equal(newConvertedValue, beforeValue)) {
         debouncedSaveFunction(newValue);
      }
   }, [selectData, disableBindDefaultValue, disableSaveChanges, isLocked]);

   return [contract, wallId, wallDataKey, showNoRowOverlay] as const;
};

export default useContractDetails;
