import {Fragment} from "react";
import {injectIntl} from "react-intl";
import {BaseComponent} from "../baseComponent";
import SystemHealthPopup from "./Popup";
import SystemHealthButton, {SystemHealthButtonProps} from "./Button";
import {SystemHealth as ISystemHealth} from "./types";
import * as systemHealthActions from "../../redux/actions/systemhealth";
import baseService from "../../../../services/base.service";
import React from "react";

const SYSTEM_HEALTH_CHECK_INTERVAL = 60000; // data fetch interval in background
const USER_MANUAL_TRIGGER_COOLDOWN = 20000; // component refetches data when user clicks the system health button, this is for rate limiting manual refetch triggers by user.

interface SystemHealthProps {
    intl: any;
    buttonProps: Partial<SystemHealthButtonProps>;
    hideLastUpdateDate?: boolean;
    disableAutoFetch?: boolean;
}

interface SystemHealthState {
    statusSummaryAnchorEl: Element | null;
    timer?: NodeJS.Timer;
    lastManualTriggerDate?: Date;
    isFetching: boolean;
}

class SystemHealth extends BaseComponent<SystemHealthProps, SystemHealthState> {
    private timerIntervalValue: number | undefined = SYSTEM_HEALTH_CHECK_INTERVAL;

    constructor(props: SystemHealthProps) {
        super(props);
        this.state = {statusSummaryAnchorEl: null, isFetching: false};
        this.initializeCheckInterval();
        this.handlePopupOpen = this.handlePopupOpen.bind(this);
    }

    private initializeCheckInterval = () => {
        const intervalConfig = import.meta.env["VITE_SYSTEM_HEALTH_CHECK_INTERVAL"];
        const isEnvDevelopment = import.meta.env["NODE_ENV"] === 'development';

        if (intervalConfig) {
            try {
                const intervalConfigValue = Number(intervalConfig);

                if (intervalConfigValue === -1) {
                    this.timerIntervalValue = undefined; // disabled auto fetch
                    console.warn("System health-check auto refresh is disabled by dev env configuration!");
                } else {
                    if (!isEnvDevelopment && intervalConfigValue < 30000) {
                        throw new Error('Health check interval must be greater than 30000 ms');
                    }

                    this.timerIntervalValue = intervalConfigValue;
                }
            } catch (e) {
                console.error("System health check configuration loading error;", e);
            }
        }
    }

    private disabledAutoFetch = (): boolean => {
        return this.props.disableAutoFetch || !Boolean(this.timerIntervalValue);
    }

    componentDidMount() {
        if (this.disabledAutoFetch()) return;
        this.getSystemHealth.apply(this);
    }

    componentWillUnmount() {
        this.state.timer && clearInterval(this.state.timer);
    }

    getTimer() {
        if (this.disabledAutoFetch()) {
            return undefined;
        }
        return setInterval(async () => await this.getSystemHealth.apply(this), this.timerIntervalValue)
    }

    async getSystemHealth(isTriggeredByUser: boolean = false) {
        if (isTriggeredByUser &&
            this.state.lastManualTriggerDate &&
            new Date().getTime() - this.state.lastManualTriggerDate.getTime() < USER_MANUAL_TRIGGER_COOLDOWN) {
            return;
        }

        this.setState({
            isFetching: true,
            lastManualTriggerDate: isTriggeredByUser ? new Date() : this.state.lastManualTriggerDate,
        });

        try {
            const res = await baseService.post("/Support/GetUserSystemHealth", {});

            // do not try to fetch again if auth error
            if (res.data.isAuthorizationError) return this.disableFunctionality.apply(this);

            this.setState({
                timer: this.state.timer ?? this.getTimer.apply(this),
                lastManualTriggerDate: isTriggeredByUser ? new Date() : this.state.lastManualTriggerDate,
                isFetching: false,
            });
            if (res.data.isError || !res.data.ReplyObject) return;

            systemHealthActions.setSystemHealth(res.data.ReplyObject as ISystemHealth);
        } catch (error) {
            this.setState({
                timer: this.state.timer ?? this.getTimer.apply(this),
                lastManualTriggerDate: isTriggeredByUser ? new Date() : this.state.lastManualTriggerDate,
                isFetching: false,
            });
        }
    }

    disableFunctionality() {
        this.state.timer && clearInterval(this.state.timer);
        this.setState({timer: undefined, isFetching: false});
    }

    handlePopupOpen(event: React.BaseSyntheticEvent) {
        this.setState({statusSummaryAnchorEl: event.currentTarget});
        this.getSystemHealth.apply(this, [true]);
    }

    handlePopupClose() {
        this.setState({statusSummaryAnchorEl: null});
    }

    render() {
        return (
            <Fragment>
                <SystemHealthButton
                    onClick={this.handlePopupOpen}
                    {...this.props.buttonProps}
                />
                <SystemHealthPopup
                    key={"system-health-popup"}
                    anchorEl={this.state.statusSummaryAnchorEl}
                    onClose={this.handlePopupClose.bind(this)}
                    isFetching={this.state.isFetching}
                    hideLastUpdateDate={this.props.hideLastUpdateDate}
                />
            </Fragment>
        );
    }
}

export default injectIntl(SystemHealth);
