const en = {
    general: {
        logout: 'Log Out',
        saveChanges: 'Save Changes',
        docs: "Docs",
        notallowedip: 'You cannot access from unauthorized IP!',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    global: {
        notvalidcode: 'invalid code',
    },
    menu: {
        menu: 'Menu',
        home: 'Home'
    },
    pages: {
        home: 'Home',
        settings: 'Settings',
        profile: 'Profile Settings'
    },
    settings: {
        darkMode: 'Dark Mode',
        layout: 'Layout'
    },
    login: {
        accountpreferences: 'Account Preferences',
        welcome: 'Welcome!',
        signInToPortal: 'Sign In to smartPulse Portal',
        meetUs: 'Meet Us!',
        onBoarding: 'smartPulse is shaping the way of energy planning and energy cost management with the digital energy technologies it develops by combining the IoT infrastructure and Artificial Intelligence algorithms.',
        joinUs: 'Become to a member of smartPulse family?',
        userName: 'Username',
        password: 'Password',
        showPasswordTooltip: 'Show Password',
        hidePasswordTooltip: 'Hide Password',
        login: 'Login',
        usernamewrong: "Username is wrong",
        passwordwrong: "Password is wrong",
        usernameorpasswordwrong: "Username or Password is wrong!",
        verifyCaptchaError: "Validate code is wrong!",
        validation: {
            userName: 'Username is required',
            password: 'Password is required'
        },
        centralAccount: "Central Account",
        submit: "Submit",
        rememberMe: "Remember me",
        validate: "Validate",
        external: {
            user: "Please use 'Central Account' button to login"
        }
    },
    profile: {
        tabGeneral: "General",
        tabSettings: "Settings",
        tabAuthorizations: "Authorizations",
        tabPassword: "Password",
        tabSecurity: "Security",
        company: "Company",
        month: "Month",
        months: "Months",
        validation: {
            oldPasswordRequired: "Old Password is required",
            newPasswordMinLength: "Password must be at least 6 characters",
            newPasswordRequired: "New Password is required",
            newPasswordConfirmMatch: "Passwords must match",
            validityDateRequired: "Validity date is required"
        },
        form: {
            password: "Password",
            newPassword: "New Password",
            passwordConfirm: "Password Confirm",
            validityDate: "Validity Date"
        },
        multiFactorAuthStatus: "Multi-Factor Auth Status",
    },
    contracts: {
        contract: 'Contract',
        contractId: 'Contract ID',
    },
};

export default en;
