import { PowerPlantTypes } from "src/components/powerplant-picker/Types";
import { ICompany, IPowerPlant } from "src/old/src/base/BaseTypes";

export interface IPlantPickerItem {
  company: ICompany;
  plant: IPowerPlant;
}

export enum IPlantPickerGroupBy {
  Company = 1,
  PlantType = 2,
  CompanyAndPlantType = 3,
}

export interface IDetailPlantPickerFilter {
  groupBy: IPlantPickerGroupBy;
  includesPlantType: Partial<PowerPlantTypes>[];
}

export interface IPlantPickerProps {
  onChange?: (newValue: Array<number>) => void;
  selectedPlants?: Array<number>;
  clearPlantsOnClose?: boolean;
}
