import * as React from 'react';

if (import.meta.env.DEV && import.meta.env.VITE_ENABLE_WHY_DID_YOU_RENDER === 'true') {
/// <reference types="@welldone-software/why-did-you-render" />
  const { default: whyDidYouRender } = await import('@welldone-software/why-did-you-render');
  const ReactRedux = await import('react-redux');
  whyDidYouRender(React, {
    exclude: [/^BrowserRouter/, /^Link/, /^Route/],
    trackHooks: true,
    trackAllPureComponents: true,
    trackExtraHooks: [[ReactRedux, 'useSelector']],
  });
}

// i18n
import './locales/i18n'; 

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// dock view
import 'dockview/dist/styles/dockview.css';

import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// contexts
import { Provider } from 'react-redux';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';

import store from './old/src/redux/stores';
import Application from './old/src/components/Application';
import { getHostEnvironmentOverrides } from './utils/hostEnvironment';
import baseService from './services/base.service';


// ----------------------------------------------------------------------

ReactDOM.render(
  <Application>
    <React.StrictMode>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <HashRouter basename="/">
              <Provider store={store}>
                <App />
              </Provider>
            </HashRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </React.StrictMode>
  </Application>,
  document.getElementById('root')
);

if (getHostEnvironmentOverrides().shouldLogUnhandledErrors) {
  let errorCount = 0;
  
  const logError = async (error: Error, localId: number) => {
    console.error(`[LOCAL_ERR_ID:${localId}] ${error.stack}`);
    try {
      if ((error as any).logRequest === undefined) {
        (error as any).logRequest = baseService.post('Support/LogClientError', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          path: window.location.hash,
        });
      }

      const response = await (error as any).logRequest;
      if (response.status !== 200 || response.data.isError || response.data.isAuthorizationError) {
        throw new Error(`[LOCAL_ERR_ID:${localId}] Error logging error: logging request failed.`);
      }

      console.info(
        `Error logged with ID: %c${response.data.ReplyObject}%c, please contact support with this ID. %c[LOCAL_ERR_ID:${localId}]`,
        'color: #2196f3; font-weight: bold;',
        'color: initial; font-weight: initial;',
        'color: #ff9800; font-weight: bold;'
      );
    } catch (error) {
      console.error(`[LOCAL_ERR_ID:${localId}] Error logging error: ${error}`);
    }
  };

  const handleLoggingError: OnErrorEventHandler = (message, source, lineno, colno, error) => {
    if (error !== undefined) {
      const currentCount = ++errorCount;
      logError(error, currentCount);
    }
    return true;
  };

  const handleUnhandledPromiseRejectionEvent = (promiseRejectionEvent: PromiseRejectionEvent) => {
    const error = new Error(promiseRejectionEvent.reason);
    if (promiseRejectionEvent.reason.stack) error.stack = promiseRejectionEvent.reason.stack;
    handleLoggingError(error.message, undefined, undefined, undefined, error);

    promiseRejectionEvent.preventDefault();
  };
  
  window.onerror = handleLoggingError;
  window.addEventListener('unhandledrejection', handleUnhandledPromiseRejectionEvent);
}
