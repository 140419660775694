import common from '../common/reducer';
import login from './login';
import gopprediction from './gopprediction';
import eaksending from './eaksending';
import dayaheadnomination from './dayaheadnomination';
import menu from './menu';
import ptfSmfPrediction from './ptfSmfPrediction';
import kgupsending from './kgupsending';
import dashboard from './dashboard';
import viewWeatherSource from './viewWeatherSource';
import bilateralagreement from './bilateralagreement';
import goppredictionplanner from './goppredictionplanner';
import groupreport from './groupreport';
import powerplantlimits from './powerplantlimits';
import gopautosettings from './gopautosettings';
import settings from './settings';
import kguprevision from './kguprevision';
import kguprevisionsettings from './kguprevisionsettings';
import gipkrssettings from './gipkrsSettings';
import systemlogs from './systemlogs';
import powerplantquestioninput from './powerplantquestioninput';
import gopplanning from './gopPlanning';
import mkudsending from './mkudsending';
import planttracking from './planttracking';
import systemhealth from './systemhealth';
import contractDetailData from '../slice/contractDetailData';
import intradaySettingsData from '../slice/intradaySettingsData';
import alertRulesData from '../slice/alertRuleData';
import {gipPlanningTickersReducer,gipPlanningDepthInfoReducer,gipPlanningTradesReducer,gipPlanningMyProposalsReducer, gipPlanningRelatedGroupProposalsReducer} from './gipPlanning/gipPlanningReducers';
import { socketApi } from '../webSocket/gipPlanningWebSocket';
import { IContractTradeDetail, IGipPlanningTickerInfo, INamedDepthInfo, INamedMyProposal, INamedRelatedGroupProposal } from '../api/types/gipPlanningWebSocketTypes';
import headerButtons from './headerbuttons';
import workspaceData, { WorkSpaceData } from "../slice/workspaceData";
import contractSelectData, { ContractSelect } from "../slice/contractSelectData";
import productionForecastSlice, { IProductionForecastState } from 'src/redux/slices/productionForecastSlice';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StoreState {
    export type All = {
        contractDetailData,
        intradaySettingsData,
        alertRulesData,
        common,
        login,
        gopprediction,
        eaksending,
        dayaheadnomination,
        menu,
        kgupsending,
        dashboard,
        viewWeatherSource,
        bilateralagreement,
        goppredictionplanner,
        groupreport,
        powerplantlimits,
        gopautosettings,
        settings,
        kguprevision,
        systemlogs,
        kguprevisionsettings,
        powerplantquestioninput,
        ptfSmfPrediction,
        gopplanning,
        gipkrssettings,
        mkudsending,
        planttracking,
        systemhealth,
        gipPlanningTickers:IGipPlanningTickerInfo[],
        gipPlanningDepthInfo:INamedDepthInfo,
        gipPlanningTrades:IContractTradeDetail[],
        gipPlanningMyProposals: INamedMyProposal,
        gipPlanningRelatedGroupProposals: INamedRelatedGroupProposal,
        socketApi,
        headerButtons,
        workspaceData: WorkSpaceData,
        contractSelectData: ContractSelect,
        productionForecast: IProductionForecastState
    }
  }

export default {
    headerButtons,
    contractDetailData,
    intradaySettingsData,
    alertRulesData,
    common,
    login,
    gopprediction,
    eaksending,
    dayaheadnomination,
    menu,
    kgupsending,
    dashboard,
    viewWeatherSource,
    bilateralagreement,
    goppredictionplanner,
    groupreport,
    powerplantlimits,
    gopautosettings,
    settings,
    kguprevision,
    systemlogs,
    kguprevisionsettings,
    powerplantquestioninput,
    ptfSmfPrediction,
    gopplanning,
    gipkrssettings,
    mkudsending,
    planttracking,
    systemhealth,
    gipPlanningTickers:gipPlanningTickersReducer,
    gipPlanningDepthInfo:gipPlanningDepthInfoReducer,
    gipPlanningTrades:gipPlanningTradesReducer,
    gipPlanningMyProposals:gipPlanningMyProposalsReducer,
    gipPlanningRelatedGroupProposals:gipPlanningRelatedGroupProposalsReducer,
    socketApi: socketApi.reducer,
    workspaceData,
    contractSelectData,
    productionForecast: productionForecastSlice
} as unknown as StoreState.All;