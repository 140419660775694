import { CSSProperties, useState, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";

import * as CommonActions from "../../redux/common/action";
import * as systemHealthActions from "../../redux/actions/systemhealth";

import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";

import { AccessAlarmIcon } from "../icons/index";

import { StylesProvider, createGenerateClassName } from "@mui/styles";
import UserSettings from "../../base/UserSettings";
import { useNavigate } from "react-router-dom";
import { BLACK_V2, DARKMODE_V2, GRAY_V2, PRIMARY_V2 } from "src/theme/palette";
import baseService from "src/services/base.service";

type TimeFields = {
   minute: number;
   second: number;
};

enum IdleMessageType {
   RESET = "RESET",
   LOGOUT = "LOGOUT",
}

const idleTimeoutTotalMiliSecondDefault: number = 7200000; // 2sa
const modalTimeoutMiliSecondDefault: number = 300000; // 5dk
const idleTimeoutMiliSecondDefault: number = idleTimeoutTotalMiliSecondDefault - modalTimeoutMiliSecondDefault;

const idleTimeoutMiliSecond: number = Number(
   import.meta.env.VITE_IDLE_TIMEOUT ?? idleTimeoutMiliSecondDefault
) as number;

const modalTimeoutMiliSecond: number = Number(
   import.meta.env.VITE_IDLE_MODAL_TIMEOUT_MS ?? modalTimeoutMiliSecondDefault
) as number;

interface IdleUserProps {
   currentTimeout: number | undefined;
}

const iconStyle: CSSProperties = {
   backgroundColor: GRAY_V2[500],
   color: "black",
   borderRadius: "50%",
   width: "45px",
   height: "45px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
};

const buttonStyle: CSSProperties = {
   fontSize: "16px",
   width: "232px",
   height: "56px",
   textTransform: "none",
   borderRadius: "50px",
   fontWeight: 400,
};

const IdleUser = (props: IdleUserProps) => {
   const intl = useIntl();
   const {
      palette: { mode: themeMode },
   } = useTheme();
   const navigation = useNavigate();
   const isDark = themeMode == "dark";
   const generateClassName = createGenerateClassName({
      productionPrefix: "msgBox",
      seed: "msgBox",
   });
   const getIdleTimeoutValue = (): number => {
      if (props.currentTimeout) return props.currentTimeout - modalTimeoutMiliSecond;
      return idleTimeoutMiliSecond;
   };
   const timeout = getIdleTimeoutValue();
   const promptBeforeIdle = modalTimeoutMiliSecond;

   const interval = useRef<NodeJS.Timeout>();
   const [remainingTimeText, setRemainingTimeText] = useState<string>("");
   const [showLogOutPopUp, setShowLogOutPopUp] = useState<boolean>(false);

   const onMessage = (data: string) => {
      switch (data) {
         case IdleMessageType.RESET:
            reset();
            break;
         case IdleMessageType.LOGOUT:
            logOut(false);
            break;
         default:
            break;
      }
   };

   const onIdle = () => {
      setShowLogOutPopUp(false);
      logOut(true);
   };

   const onActive = () => setShowLogOutPopUp(false);
   const onPrompt = () => setShowLogOutPopUp(true);
   const onAction = () => !showLogOutPopUp && message(IdleMessageType.RESET);

   const { getRemainingTime, activate, message, reset } = useIdleTimer({
      onIdle,
      onActive,
      onPrompt,
      onMessage,
      onAction,
      timeout,
      promptBeforeIdle,
      throttle: 500,
      leaderElection: true,
      crossTab: true,
   });

   useEffect(() => {
      interval.current = setInterval(() => {
         if (!showLogOutPopUp) return;

         const remaining = Math.ceil(getRemainingTime() / 1000);
         const timeFields = secondsToTime(remaining);

         let remainingText = timeFields.minute != 0 ? timeFields.minute + ":" : "";
         remainingText +=
            String(timeFields.second).padStart(2, "0") + " " + intl?.formatMessage({ id: "global.second" });

         setRemainingTimeText(remainingText);
      }, 500);

      return () => clearInterval(interval.current);
   });

   const resetIdleTimer = () => {
      activate();
   };

   const secondsToTime = (secs: number): TimeFields => {
      const divisor_for_minutes = secs % (60 * 60);
      const minutes = Math.floor(divisor_for_minutes / 60);

      const divisor_for_seconds = divisor_for_minutes % 60;
      const seconds = Math.ceil(divisor_for_seconds);

      const obj: TimeFields = {
         minute: minutes,
         second: seconds,
      };
      return obj;
   };

   const handleSignOffBackend = async () => {
      await baseService.post("/Login/Logout", {}).finally(() => {
         CommonActions.SetIsUserLoggedIn(false);
         CommonActions.setUserInformation(0, "", "", "");
         CommonActions.setUserPermissions({
            companies: [],
            powerplants: [],
            groups: [],
            sources: [],
            portfolios: [],
         });
         systemHealthActions.clearSystemHealth();
         UserSettings.clear();
      });
   };

   const getCookieValue = (name: string) => {
      const matchedValues = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)");

      return matchedValues ? matchedValues.pop() : "";
   };

   const logOut = async (forceLogout: boolean) => {
      clearInterval(interval.current);

      try {
         const response = await baseService.post("/Login/CheckUserAuthProvider", {});
         const data = response.data as boolean;
         const authenticationToken = getCookieValue("id_token");

         if (data && (authenticationToken || "") !== "") {
            /* empty */
         } else {
            await handleSignOffBackend();
         }
      } catch (error) {
         console.error(error);
         await handleSignOffBackend();
      }

      navigation({ pathname: forceLogout ? "/idlelogout" : "" });
   };

   return (
      <>
         {showLogOutPopUp && (
            <StylesProvider generateClassName={generateClassName}>
               <Dialog
                  open={true}
                  onClose={resetIdleTimer}
                  scroll={"paper"}
                  slotProps={{
                     backdrop: {
                        style: { backgroundColor: "black", opacity: "0.6" },
                     },
                  }}
               >
                  <Box
                     display="flex"
                     flexDirection="column"
                     gap="24px"
                     p="24px 32px"
                     bgcolor={isDark ? DARKMODE_V2[900] : "white"}
                  >
                     <Box display="flex" alignItems="center" gap="16px">
                        <Box style={iconStyle}>
                           <AccessAlarmIcon />
                        </Box>
                        <Box display="flex" alignItems="center" gap="8px">
                           <Typography style={{ fontSize: "16px" }}>
                              {intl.formatMessage({ id: "global.idletimeout.message1" })}:
                           </Typography>
                           <Typography style={{ fontSize: "16px", color: PRIMARY_V2[500], textTransform: "lowercase" }}>
                              {remainingTimeText}
                           </Typography>
                        </Box>
                     </Box>
                     <Typography style={{ fontSize: "16px" }}>
                        {intl.formatMessage({ id: "global.idletimeout.message2" })}
                     </Typography>
                     <Box display="flex" gap="16px">
                        <Button
                           style={{ ...buttonStyle, backgroundColor: "#F1F1F1", color: BLACK_V2[900] }}
                           onClick={() => {
                              message(IdleMessageType.LOGOUT);
                              logOut(false);
                           }}
                        >
                           {intl.formatMessage({ id: "idle.timeout.logout" })}
                        </Button>
                        <Button
                           style={{ ...buttonStyle, backgroundColor: PRIMARY_V2[500], color: "white" }}
                           onClick={resetIdleTimer}
                        >
                           {intl.formatMessage({ id: "idle.timeout.stay" })}
                        </Button>
                     </Box>
                  </Box>
               </Dialog>
            </StylesProvider>
         )}
      </>
   );
};

export default IdleUser;
