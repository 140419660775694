import _ from "lodash";
import moment from "moment";
import { PowerPlantTypes } from "src/components/powerplant-picker/Types";
import { roundToDigits, showErrorMessageBottom } from "src/utils";

import {
  CellClassParams,
  CellStyle,
  ColDef,
  EditableCallbackParams,
  GridApi,
  ValueSetterParams,
} from "@ag-grid-community/core";

import { createIntl, createIntlCache, IntlShape } from "react-intl";
import allMessages from "src/old/src/locales/_messages";
import { PlantSortType, SubSortType, UserSelectedSortOption } from "./components/Toolbar/PlantOrder/types";
import { PF_DELIVERY_INITIAL_DATE_FORMAT, PF_DRD, PF_FINAL_FORECAST } from "./constants";
import messages from "./messages";
import {
  EnabledPeriods,
  ICellInfo,
  IDelivery,
  IDeliveryLock,
  IForecastData,
  ILockedCell,
  ILockStatus,
  IPlantResolutionInfo,
  IPowerPlantData,
  IPrediction,
  IPredictionHistoryDetails,
  IRowData,
  IUserSelectedProviderInfo,
  MeasureUnit,
} from "./types";
import UserSettings from "src/old/src/base/UserSettings";

export const deliverySeparatePipe = "|";

export const keyBuilder = (start: string, end: string) => `${start} ${deliverySeparatePipe} ${end}`;

export const getUtc = (date: Date, offset: number) => moment(date).subtract(offset, "minutes");

export const generateDeliveryKey = (p: DeliveryType): string => {
  return keyBuilder(
    getUtc(p.deliveryStart, p.deliveryStartOffset).toISOString(),
    getUtc(p.deliveryEnd, p.deliveryEndOffset).toISOString()
  );
};

export const extractDeliveryPartFromDeliveryKey = (dl: string) => {
  const values = dl.split(` ${deliverySeparatePipe} `);
  if (values.length === 0) return {};
  return {
    deliveryStart: values[0],
    deliveryEnd: values[1],
  };
};

export const getDateInIsoString = (date: Date, offset: number) =>
  moment(date, PF_DELIVERY_INITIAL_DATE_FORMAT).utcOffset(offset, true).toISOString();

export const getDistinctDeliveries = (forecastData: IForecastData[]): IDelivery[] => {
  return _.chain(forecastData.flatMap((fd) => fd.predictions))
    .uniqBy((pv) => generateDeliveryKey(pv))
    .map<IDelivery>((pv) => ({
      deliveryStart: new Date(pv.deliveryStart),
      deliveryStartOffset: pv.deliveryStartOffset,
      deliveryStartInIsoString: getDateInIsoString(pv.deliveryStart, pv.deliveryStartOffset),
      deliveryEndInIsoString: getDateInIsoString(pv.deliveryEnd, pv.deliveryEndOffset),
      deliveryEnd: new Date(pv.deliveryEnd),
      deliveryEndOffset: pv.deliveryEndOffset,
      period: getUtc(pv.deliveryEnd, pv.deliveryEndOffset ?? 0).diff(
        getUtc(pv.deliveryStart, pv.deliveryStartOffset ?? 0),
        "minutes"
      ),
    }))
    .orderBy([(x) => moment(x.deliveryStartInIsoString).valueOf(), (x) => moment(x.deliveryEndInIsoString).valueOf()])
    .value();
};

export type DeliveryType = IDelivery | IDeliveryLock | IPrediction | IPredictionHistoryDetails;
export const isSameDelivery = (d1: DeliveryType, d2: DeliveryType): boolean => {
  return generateDeliveryKey(d1) === generateDeliveryKey(d2);
};

const getSameOffsetDateTimes = (d: DeliveryType) => {
  const ds = moment(d.deliveryStart);
  const de = moment(d.deliveryEnd);
  if (d.deliveryStartOffset === d.deliveryEndOffset) return [ds, de];

  // bring to same offset as deliverystart
  de.add(d.deliveryStartOffset - d.deliveryEndOffset, "minutes");

  return [ds, de];
};

export const generateDisplayDeliveryString = (d: IDelivery | IPrediction | IPredictionHistoryDetails): string => {
  const [ds, de] = getSameOffsetDateTimes(d);

  return `${ds.format("HH:mm")}-${de.format("HH:mm")}`;
};

export const generateConsecutiveUniqueDeliveryString = (
  delivery: IDelivery,
  deliveries: IDelivery[],
  direction: "prev" | "next"
) => {
  const period = delivery.period * (direction === "prev" ? -1 : 1);
  const nextDeliveryStart = moment(delivery.deliveryStart).add(period, "minutes");
  const nextDeliveryEnd = nextDeliveryStart.add(period, "minutes");

  return `${nextDeliveryStart.format("HH:mm")}-${nextDeliveryEnd.format("HH:mm")}`;
};

export const findDeliveryPrediction = (delivery: IDelivery, predictions: IPrediction[]): IPrediction | undefined =>
  predictions.find((p) => isSameDelivery(p, delivery));

export const getColName = (ppId: number, dataName: "prediction" | "provider" | "state"): string => {
  return `pp${ppId}_${dataName}`;
};

export const getHeaderClassWithIcons = (powerPlantType: PowerPlantTypes) => {
  let icon = "text-center ";
  switch (powerPlantType) {
    case PowerPlantTypes.Hydro:
      icon += "hydro-icon";
      break;
    case PowerPlantTypes.Thermal:
      icon += "coal-icon";
      break;
    case PowerPlantTypes.Wind:
      icon += "wind-icon";
      break;
    case PowerPlantTypes.Solar:
      icon += "solar-icon";
      break;
    case PowerPlantTypes.NaturalGas:
      icon += "gas-icon";
      break;
    case PowerPlantTypes.GeoThermal:
      icon += "geothermal-icon";
      break;
    default:
      break;
  }
  return icon;
};

export const getCellLockStatus = (
  params:
    | CellClassParams
    | EditableCallbackParams
    | ValueSetterParams
    | {
      colDef: ColDef;
      data: IRowData;
    },
  lockedCells: ILockedCell[],
  deliveries: IDelivery[],
  checkNeighbors: boolean
): ILockStatus => {
  const lockStatus: ILockStatus = {
    isLocked: false,
    isTopNeighborLocked: false,
    isBottomNeighborLocked: false,
  };
  if (params.data.isUtilRow) return lockStatus;

  const currField = params.colDef.field!;

  if (
    lockedCells.length > 0 &&
    lockedCells.find(
      (lc) =>
        lc.fieldName === currField && generateDeliveryKey(lc.delivery) === generateDeliveryKey(params.data.delivery!)
    )
  ) {
    lockStatus.isLocked = true;
    if (checkNeighbors) {
      lockStatus.isTopNeighborLocked = Boolean(
        lockedCells.find(
          (lc) =>
            lc.fieldName === currField &&
            generateDeliveryKey(lc.delivery) ===
            generateConsecutiveUniqueDeliveryString(params.data.delivery, deliveries, "prev")
        )
      );
      lockStatus.isBottomNeighborLocked = Boolean(
        lockedCells.find(
          (lc) =>
            lc.fieldName === currField &&
            generateDeliveryKey(lc.delivery) ===
            generateConsecutiveUniqueDeliveryString(params.data.delivery, deliveries, "next")
        )
      );
    }
  }

  return lockStatus;
};

export const checkPlantDiversion = (
  forecastData: IForecastData[] | null,
  delivery: IDelivery,
  newValue: number,
  measureUnit: MeasureUnit,
  powerPlantId: number
) => {
  if (forecastData == null || forecastData.length == 0) return false;

  const pd = forecastData.find((pd) => pd.powerPlantId === powerPlantId);
  if (!pd) return;

  newValue = measureUnit === MeasureUnit.MW ? convertToKWh(newValue)! : newValue; // convert to KWh for comparison with the original value

  let productionDiversity = pd.productionDiversity;
  if (productionDiversity == null || productionDiversity == "") return false;
  const oldValue = pd.predictions.find((pv) => generateDeliveryKey(pv) === generateDeliveryKey(delivery))?.value ?? 0;

  let delta = 0;
  if (productionDiversity.startsWith("%")) {
    productionDiversity = productionDiversity.replace("%", "");
    delta = (oldValue * parseFloat(productionDiversity)) / 100;
  } else delta = parseFloat(productionDiversity);

  const difference = Math.round(Math.abs(oldValue - newValue) * 100) / 100;

  return difference > delta;
};

export const convertRowDataValues = (rowData: IRowData[], multiplier: number) => {
  rowData.forEach((row) => {
    if (!["prediction", "total"].includes(row.type)) return;

    Object.keys(row).forEach((key) => {
      if (key.endsWith("_prediction") && row[key]) {
        row[key] *= multiplier;
      }
    });
  });
};

export const getEmptyCells = (gridApi: GridApi | undefined, powerPlantIds: number[], lockedCells: ILockedCell[]) => {
  const emptyCells: ICellInfo[] = [];
  gridApi?.forEachNode((node) => {
    const row: IRowData = node.data;
    if (row.type !== "prediction") return;
    const rowId = node.id!;
    const lockedRowCells = lockedCells
      .filter((lc) => isSameDelivery(row.delivery!, lc.delivery))
      .map((lc) => lc.fieldName);

    const pcns = powerPlantIds?.map((ppId) => getColName(ppId, "prediction"));
    Object.keys(row).forEach((key) => {
      if (lockedRowCells.includes(key)) return;
      if (pcns.length > 0) {
        if (pcns.includes(key) && row[key] == null) {
          emptyCells.push({
            rowId,
            cellKey: key,
            powerPlantId: Number(key.slice(2).split("_")[0]), // pp123_prediction,
            forHour: row.rowTitle!,
          });
        }
      } else if (key.endsWith("_prediction") && row[key] == null) {
        emptyCells.push({
          rowId,
          cellKey: key,
          powerPlantId: Number(key.slice(2).split("_")[0]), // pp123_prediction
          forHour: row.rowTitle!,
        });
      }
    });
  });

  return emptyCells;
};

export const processValueRows = (
  deliveries: IDelivery[],
  forecastData: IForecastData[],
  defaultValue: number | null,
  formatMessage: Function
) => {
  const predictionRows: IRowData[] = [];
  const totalsRow: IRowData = {
    type: "total",
    rowTitle: formatMessage(messages.total),
    cellStyle: {
      fontWeight: "bold",
    } as CellStyle,
    isUtilRow: true,
  };

  const lockedCellsProvider: ILockedCell[] = [];
  const lockedCellsUser: ILockedCell[] = [];
  for (const d of deliveries) {
    const currRow: IRowData = {
      type: "prediction",
      rowTitle: generateDisplayDeliveryString(d),
      delivery: d,
    };

    for (const fd of forecastData) {
      const pcn = getColName(fd.powerPlantId, "prediction");
      const dp = findDeliveryPrediction(d, fd.predictions);

      if (totalsRow[pcn] == null) totalsRow[pcn] = 0;

      currRow[pcn] = dp?.value ?? defaultValue;
      if (dp && (new Date(dp.deliveryEnd).valueOf() - new Date(dp.deliveryStart).valueOf()) / (1000 * 60) === d.period) {
        const deliveryLock = fd.deliveryLocks.find((pl) => isSameDelivery(dp, pl));
        if (deliveryLock?.lockState === 1) {
          lockedCellsProvider.push({
            fieldName: pcn,
            delivery: d,
          });
        } else if (deliveryLock?.lockState === -1) {
          lockedCellsUser.push({
            fieldName: pcn,
            delivery: d,
          });
        }

        totalsRow[pcn] += dp?.value ?? 0;
      }
    }

    predictionRows.push(currRow);
  }

  return { predictionRows, totalsRow, lockedCellsProvider, lockedCellsUser };
};

export const convertToKWh = (value?: number | null, applyDefaultRounding = true) => {
  if (value == null) return null;

  const convertedValue = value * 1000;
  return applyDefaultRounding ? roundToDigits(convertedValue, PF_DRD) : convertedValue;
};

export const convertToMWh = (value?: number | null, applyDefaultRounding = true) => {
  if (value == null) return null;

  const convertedValue = value * 0.001;
  return applyDefaultRounding ? roundToDigits(convertedValue, PF_DRD) : convertedValue;
};

export const convertTo = (value: number | null | undefined, measureUnit: MeasureUnit, applyDefaultRounding = true) =>
  measureUnit === MeasureUnit.MW
    ? convertToMWh(value, applyDefaultRounding)!
    : convertToKWh(value, applyDefaultRounding)!;

/**
 * Replaces locale's decimal seperator with '.'. If number is not a number, tries to replace ',' with '.' as a fallback __(unsafe,unreliable)__.
 * @param value value to parse
 * @param decimalSeperator locale's decimal seperator
 * @returns parsed number. 0 if value is null, undefined or empty string
 */
export const parseNumber = (value: string | null, decimalSeperator: string): number => {
  const swappedSeperator = value?.toString().replace(decimalSeperator, ".") ?? null;
  let parsedValue = Number(swappedSeperator);
  if (isNaN(parsedValue)) {
    // !fallback, unreliable
    parsedValue = Number(swappedSeperator?.replace(/,/g, ".") ?? null);
  }
  return parsedValue;
};

export const getPlantTypeRenderedName = (intl: IntlShape, type: PowerPlantTypes) =>
  intl.formatMessage({ id: `global.powerplant.sourcetype.${type}`, defaultMessage: `Type ${type}` });

export const orderPlantDataByUserSortOption = (args: {
  powerPlantData: IPowerPlantData[];
  intl: IntlShape;
  userSelectedProvider: IUserSelectedProviderInfo[];
  userSortOption: UserSelectedSortOption;
  period: EnabledPeriods;
  plantResolutions: IPlantResolutionInfo[];
}) => {
  const { intl, userSortOption, userSelectedProvider, powerPlantData, period, plantResolutions } = args;
  const sortingOrder = _.lowerFirst(SubSortType[userSortOption.subType ?? SubSortType.Asc]) as "asc" | "desc";

  if (
    (_.isNil(userSortOption.sortType) && _.isNil(userSortOption.subType)) ||
    userSortOption.sortType === PlantSortType.Resolution
  )
    return _.orderBy(
      powerPlantData,
      [
        (x) =>
          (plantResolutions.find((r) => r.plantId === x.powerPlantId)?.resolution ?? EnabledPeriods.P60) === period,
        (x) => x.powerPlantName,
      ],
      ["desc", "asc"]
    );

  return _.orderBy(
    powerPlantData,
    (x) => {
      switch (userSortOption.sortType) {
        case PlantSortType.InstalledPower:
          return x.installedPower;
        case PlantSortType.UpdatingDate:
          return x.updateInfo ? moment(x.updateInfo) : false;
        case PlantSortType.Company:
          return x.companyName;
        case PlantSortType.PlantType:
          return getPlantTypeRenderedName(intl, x.powerPlantType);
        case PlantSortType.Provider:
          return (
            userSelectedProvider.find((userProvider) => userProvider.plantId === x.powerPlantId)?.providerName ??
            PF_FINAL_FORECAST
          );
        case PlantSortType.Resolution:
          return plantResolutions.find((a) => a.plantId === x.powerPlantId)?.resolution ?? 0;
        default:
          return x.powerPlantName;
      }
    },
    sortingOrder
  );
};

export const checkIsSameResolution = (
  plantId: number,
  plantResolutions: IPlantResolutionInfo[],
  period: EnabledPeriods
) => {
  return (plantResolutions.find((x) => x.plantId === plantId)?.resolution ?? EnabledPeriods.P60) === period;
};

export const getSameRenderedDeliveries = (predictionRows: IRowData[]) => {
  const result: string[] = [];
  for (let index = 0; index < predictionRows.length; index++) {
    const p = predictionRows[index];
    const sameDelivery = predictionRows.find((x, i) => x.rowTitle === p.rowTitle && i !== index);
    if (sameDelivery) result.push(sameDelivery.rowTitle!);
  }
  return result;
};

export const getDisplayedTimelines = (
  startDate: Date,
  userTimeZone: string,
  period: EnabledPeriods = EnabledPeriods.P60
): IDelivery[] => {
  const startDay = moment(startDate).tz(userTimeZone).startOf("day");
  const endDay = startDay.clone().add(1, "days");

  const result: IDelivery[] = [];
  const diffHours = endDay.diff(startDay, "hours");

  const multiplier = 60 / period;

  for (let addingHours = 0; addingHours < diffHours; addingHours++) {
    for (let i = 0; i < multiplier; i++) {
      const start = startDay
        .clone()
        .add(addingHours, "hour")
        .add(i * period, "minutes");
      const end = startDay
        .clone()
        .add(addingHours, "hour")
        .add((i + 1) * period, "minutes");
      result.push(<IDelivery>{
        deliveryStart: start.toDate(),
        deliveryStartOffset: start.utcOffset(),
        deliveryEnd: end.toDate(),
        deliveryEndOffset: end.utcOffset(),
      });
    }
  }

  return result;
};

export const useIntOutsideComponent = (): IntlShape => {
  const locale = localStorage.getItem("locale") ?? "en";
  const cache = createIntlCache();
  const intl = createIntl({ locale, messages: allMessages[locale] }, cache);
  return intl;
};

export const checkHasWarningForEmptyCells = (forecastData: IForecastData[], emptyCells: ICellInfo[]) => {
  const warningPlantNames: { ppId: number; ppName: string }[] = [];
  const now = moment.utc();
  for (const [ppIdInString, emptyCellsOfPlant] of Object.entries(_.groupBy(emptyCells, (x) => x.powerPlantId))) {
    const ppId = _.parseInt(ppIdInString);
    const ppData = forecastData.find((x) => x.powerPlantId === ppId);
    if (!ppData || emptyCellsOfPlant.length === 0) continue;

    const firstAvailableContract = getFirstAvailableContract(ppData.ffSavingOffsetInMinute, ppData.plantTimeZone);
    const doorClosingTime = getDoorClosingTime(ppData.ffSavingOffsetInMinute, ppData.plantTimeZone);

    const isAllBeforeNullValue =
      ppData.predictions.filter((x) => _.isNil(x.value)).length === ppData.predictions.length;

    const allTimesHasValue = isAllBeforeNullValue && emptyCellsOfPlant.length === 0;

    const hasEmptySameDayFFValues =
      !allTimesHasValue &&
      emptyCellsOfPlant.some((ec) => {
        const dlStart = extractDeliveryPartFromDeliveryKey(ec.rowId).deliveryStart;
        if (!dlStart) return false;
        const dl = moment(dlStart).tz(ppData.plantTimeZone);
        return now.isSameOrBefore(doorClosingTime) && dl.isSameOrAfter(firstAvailableContract);
      });

    const hasEmptyValuesExcluedLocks =
      !allTimesHasValue &&
      ppData.deliveryLocks.length > 0 &&
      emptyCellsOfPlant.filter(
        (ec) => ppData.deliveryLocks.filter((dl) => generateDeliveryKey(dl as DeliveryType) !== ec.rowId).length > 0
      );

    if (hasEmptyValuesExcluedLocks || hasEmptySameDayFFValues)
      warningPlantNames.push({ ppId: ppData.powerPlantId, ppName: ppData.powerPlantName });
  }
  return warningPlantNames;
};

export const getFirstAvailableContract = (ffSavingOffsetInMinute: number, timezone?: string) => {
  timezone ??= UserSettings.getUserTimeZone();

  const now = moment().utc().tz(timezone).add(ffSavingOffsetInMinute, "minutes");
  const contractTime = moment.tz([now.year(), now.month(), now.date(), now.hour()], timezone).add(1, "hours");
  return contractTime;
};

export const getDoorClosingTime = (ffSavingOffsetInMinute: number, timezone?: string) => {
  const contractTime = getFirstAvailableContract(ffSavingOffsetInMinute, timezone);
  return contractTime.clone().add(ffSavingOffsetInMinute * -1, "minutes");
};

export const shouldChangeBgColorOnProviderChange = (delivery: IDelivery) => {
  const nowInStartHour = moment.utc().startOf("hour");
  return moment(delivery?.deliveryStartInIsoString).isSameOrAfter(nowInStartHour);
}