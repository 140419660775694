export enum PlantSortType {
  PlantName = 1,
  InstalledPower = 2,
  UpdatingDate = 3,
  Provider = 4,
  Company = 5,
  PlantType = 6,
  Resolution = 7,
}

export enum SubSortType {
  Asc = 1,
  Desc = 2,
}

export interface ISubItemLabel {
  key: number;
  label: string;
}

export enum ShowResolution {
  All = 1,
  OnlySelected = 2,
}

export interface UserSelectedSortOption {
  sortType?: PlantSortType;
  subType?: SubSortType;
}

export type SubOptionType = "Alphabetical" | "Numeric" | "Date" | "Resolution";

export interface IPlantSortByItem {
  label: string;
  sortType: PlantSortType;
  subLabels: Array<ISubItemLabel>;
  show: boolean;
}
