/* eslint-disable @typescript-eslint/no-explicit-any */
import { EContractState } from "src/enums/contract-state.enum";
import { EContractType } from "src/enums/contract-type.enum";
import { ERegion } from "src/enums/region.enum";
import { UserDefaultBuySellCompany } from "src/models/gip-planning/UserDefaultBuySellCompanies";
import { EIntradayPlanningTab } from "src/old/src/pages/intraday/enums/EIntradayPlanningTab";
import { ETickerType } from "src/old/src/pages/intraday/enums/ETickerType";

export enum SocketConnectionType {
   ONLY_HOURLY_CONTRACTS = 0,
   ALL_CONTRACTS = 1,
}

export interface IReducedProposalContainerModel {
   reducedBuyProposals: IReducedProposal[];
   reducedSellProposals: IReducedProposal[];
}
export interface IReducedProposal {
   id: string;
   isMineItem: number;
   price: number;
   proposalType: number;
   quantity: number;
   xBidId?: string;
   cumulativeQuantity: number;
   myProposal?: IMyProposalDetail;
   isMyProposal: boolean;
}
export interface IChartDataModel {
   x: number;
   y: number;
}
export interface IDepthChartDataContainerModel {
   sumOfSellOrderQuantities: number;
   sumOfBuyOrderQuantities: number;
   ptfPoints: IChartDataModel[];
   sellOrderPoints: IChartDataModel[];
   buyOrderPoints: IChartDataModel[];
   maxQuantity: number;
   maxPrice: number;
   minPrice: number;
}
export interface IPowerPlantInstrument {
   actualProduction: number;
   delta: number;
   fdppDelta: number;
   finalDailyProductionProgram: number;
   firstFdpp: number;
   instrument: string;
   productionPrediction: number;
}
export interface IPowerPlant {
   powerPlantId: number;
   powerPlantName: string;
   instruments: IPowerPlantInstrument[];
}
export interface IContractPositionInfoNamed {
   [selectedWallKey: string]: IContractPositionInfo;
}
export interface IContractPositionInfo {
   wallSelectionKey: string;
   actualConsumption: number;
   biliteralAggreementQuantity: number;
   dayAheadBlockQuantity: number;
   dayAheadDefaultQuantity: number;
   dayAheadElasticQuantity: number;
   deliveredDeloading: number;
   deliveredLoading: number;
   deliveredMwhDeloading: number;
   deliveredMwhLoading: number;
   extraPosition: number;
   intradayBlockQuantity: number;
   intradayDefaultQuantity: number;
   netBlockVolume: number;
   netDefaultVolume: number;
   netPosition: number;
   productionPrediction: number;
   retailQuantity: number;
   totalDeloadingInstructionAmount: number;
   totalLoadingInstructionAmount: number;
   virtualBiliteralAggreementQuantity: number;
   fromUpDown?: IInterContractItemModel;
}

export interface IInterContractItemModel {
   total?: IFromUpDownItemModel;
   bA?: IFromUpDownItemModel;
   consumptionForcast?: IFromUpDownItemModel;
   dayAhead?: IFromUpDownItemModel;
   extraPos?: IFromUpDownItemModel;
   intraday?: IFromUpDownItemModel;
   prdForcast?: IFromUpDownItemModel;
}

export interface IFromUpDownItemModel {
   up: number;
   down: number;
}

export interface IContractProposalInfo {
   buyContractMyPrice: number;
   buyContractMyQuantity: number;
   sellContractMyPrice: number;
   sellContractMyQuantity: number;
}
export interface IContractProductionInfo {
   actualProduction: number;
}
export interface IContractDepth {
   index: number;
   rating: number;
}
export interface IDepthSummary {
   buyDepth: IContractDepth[];
   sellDepth: IContractDepth[];
}
export interface INamedDepthInfo {
   [contractDataKey: string]: {
      buyDepths: IDepthInfo[];
      sellDepths: IDepthInfo[];
      depthSummary: IDepthListSummary;
   };
}
export interface IDepthListSummary {
   maxBuyCumulativeAmount: number;
   maxSellCumulativeAmount: number;
   maxCumulativeAmount: number;
   buyDepthCount: number;
   sellDepthCount: number;
}
export interface IDepthInfo {
   id: string;
   isMineItem: number;
   price: number;
   proposalType: number;
   quantity: number;
   xBidId?: string;
}

export interface IMyProposalDetailTag {
   title: string;
   value: string;
}
export interface IMyProposalDetailMatch {
   quantity: number;
   price: number;
   createDate: string;
}

export interface IMyProposalDetail {
   createDate: string;
   companyId: number;
   proposalId: string;
   proposalXBidId: string;
   duration: number;
   contractIndex: number;
   proposalType: number;
   price: number;
   quantity: number;
   remainingQuantity: number;
   matchedPrice: number;
   matchedQuantity: number;
   totalMatchedQuantity: number;
   status: ProposalStatusEnum;
   explanation: string;
   message: string;
   matches: IMyProposalDetailMatch[];
   createUser: number;
   createUserName: string;
   source: string;
   smartBotId: number;
   relatedProposalId: string;
   tags: IMyProposalDetailTag[];
   isIceberg: boolean;
   optionType: number;
   validityDate: string;
   lastModifyTimestamp: number;
   integrationUniqueId: string;
   revisionNo: number;
   totalQuantity: number;
   peakPriceDelta: number;
   clipSize: number;
   isHibernated: boolean;
   privateTradeSyncFailed: boolean;
}
export interface IMyProposal {
   proposals: IMyProposalDetail[];
   productType: number;
   duration: number;
   bestBuyPrice: number;
   bestSellPrice: number;
   weightedAverageActiveBuyPrice: number;
   weightedAverageActiveSellPrice: number;
   totalActiveBuyQuantity: number;
   totalActiveSellQuantity: number;
   weightedAverageTradeBuyPrice: number;
   weightedAverageTradeSellPrice: number;
   totalTradeBuyQuantity: number;
   totalTradeSellQuantity: number;
   proposalsCount: number;
}

export interface INamedMyProposal {
   [contractDataKey: string]: IMyProposal[];
}

export interface IRelatedGroupProposal {
   groupId: number;
   region: ERegion;
   companyName: string;
}

export interface INamedRelatedGroupProposal {
   [contractDataKey: string]: Record<string, IRelatedGroupProposal>;
}

export interface ISystemMarginalPriceHistory {
   date: string;
   value: number;
}

export interface IIntradayAuctionSessions {
   Id: number;
   Price: number;
}

export interface ISystemDirectionHistory {
   date: string;
   deloading: number;
   loading: number;
   value: number;
}
export interface IBoardContractMarketInfo {
   contractDataKey: string;
   downwardImbalancesPaymentPrice: number;
   downwardImbalancesPaymentQuantity: number;
   intradayAuctionPrice: number;
   intradayAuctionSessions: IIntradayAuctionSessions[];
   mcp: number;
   smp: number;
   systemDirection: number;
   systemDirectionHistory: ISystemDirectionHistory[];
   systemDirectionType: number;
   systemMarginalPriceHistory: ISystemMarginalPriceHistory[];
   upwardImbalancesPaymentPrice: number;
   upwardImbalancesPaymentQuantity: number;
}
export interface IBoardContractConstant {
   contractDataKey: string;
   contractId: string;
   contractName: string;
   displayName: string;
   deliveryStart: string;
   deliveryEnd: string;
   periodId: number;
   periodName: string;
   tradingPhaseEnd: string;
   durationMinutes: number;
   contractType: EContractType;
   displayShortName: string;
   productType: string;
}
export interface IBoardContractDynamic {
   contractDataKey: string;
   isOpenForTrade: boolean;
   remainingTime: number;
   remainingTimeSeconds: number;
   remainingTimePercentage: number;
   state: EContractState;
}
export interface IBoardContractBests {
   contractDataKey: string;
   buyContractBestPrice: number;
   buyContractBestQuantity: number;
   sellContractBestPrice: number;
   sellContractBestQuantity: number;
}

export interface INamedContractDetail {
   [contractDataKey: string]: IContractTradeDetail[];
}

export interface IContractTradeDetail {
   buyProposalId?: number;
   date: string;
   direction: number;
   id: string;
   price: number;
   quantity: number;
   sellProposalId?: number;
   spread: number;
   isGroupTrade: number;
}
export interface IContractTradeInfo {
   contractDataKey: string;
   lastTradeDate: string;
   lastTradePrice: number;
   lastTradePriceChange: number;
   lastTradeQuantity: number;
}
export interface IContractRucValueCompany {
  companyName: string;
  companyId: number;
  ruc: number;
  totalNeedMatch: number;
  remainingTransactionCount: number;
  tgs?: number;
}
export interface IContractRucValue {
  companies: IContractRucValueCompany[];
  needMatchCompanies: IContractRucValueCompany[];
  color: string;
  needMatchColor: string;
  min: number;
  max: number;
  tgsColor: string;
  tgsInfoOfCompanies: IContractRucValueCompany[];
}
export enum LoadingStateEnum {
   NOT_LOADED = 0,
   LOADING = 1,
   LOADED = 2,
}
export enum ContractDetailsLoadedStatus {
   NOT_LOADED = 0,
   LOADING = 1,
   LOADED = 2,
}

export enum ProposalStatusEnum {
   None = 1,
   Active = 2,
   Passive = 4,
   Hibernated = 5,
   Matched = 8,
   PartiallyMatched = 16,
   Cancelled = 32,
   Expired = 64,
   Realized = 128,
}

export interface IBoardContractSmartBotListenerInfoNamed {
   [selectedWallKey: string]: IBoardContractSmartBotListenerInfo;
}
export interface IBoardContractSmartBotListenerInfo {
   myListenerCount: number;
   myListenerAlertRuleIds: number[];
   othersListenerCount: number;
   othersListenerAlertRuleIds: number[];
}
export interface IBoardContract {
   contractDataKey: string;
   contractId: string;
   contractConstant: IBoardContractConstant;
   contractDynamic: IBoardContractDynamic;
   contractBests: IBoardContractBests;
   depthSummary: IDepthSummary;
   marketInfo: IBoardContractMarketInfo;
   positionInfo: IContractPositionInfoNamed;
   productionInfo: IContractProductionInfo;
   proposalInfo: IContractProposalInfo;
   tradeInfo?: IContractTradeInfo;
   // trades: IContractTradeDetail[];
   // depthInfo: IDepthInfo[];
   dynamicColumn: IGipPlanningDynamicColumnNamed;
   smartBotListenerInfo: IBoardContractSmartBotListenerInfoNamed;
   rucValue: IContractRucValue;
   detailsLoadedStatus: ContractDetailsLoadedStatus;
   updatedStamp: number;
   consumptionInfo?: IContractProductionInfo;
   instructionInfo?: IInstructionInfoModel;
}

export interface IBoardContractUpdateModel {
   contractDataKey: string;
   contractConstant?: IBoardContractConstant;
   contractDynamic?: IBoardContractDynamic;
   contractBests?: IBoardContractBests;
   depthSummary?: IDepthSummary;
   marketInfo?: IBoardContractMarketInfo;
   positionInfo?: IContractPositionInfoNamed;
   productionInfo?: IContractProductionInfo;
   proposalInfo?: IContractProposalInfo;
   tradeInfo?: IContractTradeInfo;
   myProposals?: IMyProposal[];
   rucValue?: IContractRucValue;
   detailsLoadedStatus?: ContractDetailsLoadedStatus;
   consumptionInfo?: IContractProductionInfo;
   instructionInfo?: IInstructionInfoModel;
}

export interface IInstructionInfoModel {
   deliveredDeloading: number;
   deliveredLoading: number;
   deliveredMwhDeloading: number;
   deliveredMwhLoading: number;
   totalDeloadingInstructionAmount: number;
   totalLoadingInstructionAmount: number;
}

export interface IAlertRuleListenerContract {
   contractId: string;
   deliveryStart: string;
}
export interface IAlertRuleListener {
   id: number;
   sourceId: number;
   sourceName: string;
   selectedContracts: IAlertRuleListenerContract[];
   selectedMatchType?: number;
}

export interface IAlertRuleAction {
   id: number;
   scriptType: number;
   alertRuleLibraryScriptId: number;
   parameters: IAlertRuleParameter[];
   ruleText: string;
   ruleAction: string;
   companyId: number;
}
export interface IAlertRuleParameter {
   parameterId: number;
   expression: string;
}

export interface IAlertRule {
   id: number;
   name: string;
   listeners: IAlertRuleListener[];
   mode: number;
   modeText: string;
   actions: IAlertRuleAction[];
   sharedStatus: number;
   createUser: number;
   createUserName: string;
}
export interface ILibraryScriptParameter {
   id: number;
   name: string;
}
export interface ILibraryScript {
   id: number;
   name: string;
   parameters: ILibraryScriptParameter[];
}

export interface ISystemStatusServices {
   additionalData?: any;
   isConnected: boolean;
   isHealthy: boolean;
   lastUpdateDate: string;
   regionId: number;
   message?: string;
   title: string;
}

export interface IGipPlanningWebSocketCompany {
   id: number;
   name: string;
   fullName: string;
   powerPlants: number[];
   timeZone: string;
}
export interface ISystemStatus {
   isSystemHealthy: boolean;
   services: ISystemStatusServices[];
   connectedCompanies: IGipPlanningWebSocketCompany[];
   rejectedCompanies: IGipPlanningWebSocketCompany[];
}

export interface IServerTime {
   offset: string;
   serverTime: string;
   timeZone: string;
}

export interface IProposalLimitRule {
   minPrice?: number;
   maxPrice?: number;
   maxPeakPrice?: number;
   minPeakPrice?: number;
   maxTotalQuantity?: number;
   minTotalQuantity?: number;
   maxQuantity?: number;
   minQuantity?: number;
}
export interface IProposalLimit {
   id: number;
   region: string;
   complexType: string;
   orderType: string;
   messageId: string;
   defaultMessage: string;
   limitationRule: string;
   rule: IProposalLimitRule;
}

export interface IGipPlanningWebSocketUserMessage {
   messageType:
      | "GLOBAL_WARNING"
      | "GLOBAL_ERROR"
      | "GLOBAL_SUCCESS"
      | "SYSTEM_MESSAGE"
      | "SMARTPULSE_IDM_MESSAGE_WARNING"
      | "SMARTPULSE_IDM_MESSAGE_WARNING_SUBMITOFFERERROR"
      | "SMARTPULSE_IDM_MESSAGE_INFO";
   parameters: any;
   localization: any;
   code?: string;
}
export enum GipPlanningWebSocketConnectionStatus {
   CONNECTING = 0,
   OPEN = 1,
   CLOSING = 2,
   CLOSED = 3,
}

export interface IGipPlanningGroupCompany {
   companyId: number;
   companyName: string;
}
export interface IGipPlanningGroupPowerPlant {
   companyId: number;
   powerPlantId: number;
   powerPlantName: string;
}
export interface IGipPlanningUserInfo {
   companyId: number;
   groupId: number;
   userFullName: string;
   userId: number;
   groupCompanies: IGipPlanningGroupCompany[];
   groupPowerPlants: IGipPlanningGroupPowerPlant[];
   userCompanies: number[];
}
export interface IGipPlanningDynamicColumnDetail {
   companyId: number;
   portfolioId: number;
   detailId: number;
   value: string;
}

export interface IGipPlanningDynamicColumnNamed {
   [selectedWallKey: string]: IGipPlanningDynamicColumn;
}
export interface IGipPlanningDynamicColumn {
   contractDataKey: string;
   columns: IGipPlanningDynamicColumnDetail[];
}

export interface IGipPlanningCompanyRucModel {
  companyId: number;
  rucValue: number;
  instrumentHour: number;
  totalNeedMatchforRuc: number;
  instrumentDate: string;
  remainingTransactionCount: number;
  tgs?: number;
}
export interface IGipPlanningGroupRucRadValues {
   groupRadDay1: number;
   groupRadDay2: number;
   groupRemainingTransactionCountDay1: number;
   groupRemainingTransactionCountDay2: number;
   groupTotalNeedMatchDay1: number;
   groupTotalNeedMatchDay2: number;
}
export interface IGipPlanningCompanyRucRadValues {
   companyRucList: IGipPlanningCompanyRucModel[];
}
export interface IGipPlanningRadRiskInfo {
   companyRucRadValues: IGipPlanningCompanyRucRadValues;
   groupRucRadValues: IGipPlanningGroupRucRadValues;
}

export interface IGipPlanningLiveSettings {
   allowedStatesForProposalUpdate: number[];
   blockOfferVersion: number;
   canCreatePassiveOffer: boolean;
   canEditClipSize: boolean;
   canEditIcebergOrder: boolean;
   canEditIcebergPrice: boolean;
   canEditPeakPrice: boolean;
   canEditTotalQuantity: boolean;
   canSeeTradeHitSide: boolean;
   contractTitleDisplayType: number;
   currency: string;
   currencySymbol: string;
   disableBlockProposals: boolean;
   disableNetPositionTooltip: boolean;
   enableHibernatedProposals: boolean;
   enableIcebergProposals: boolean;
   enableProposalTreeView: boolean;
   enableValidityDate: boolean;
   hideMcpMultiplierButtons: boolean;
   hideNewProposalOptions: boolean;
   hideProductionPlanning: boolean;
   icebergExplanationId: number;
   intradayPlanningColumns: string[];
   isUsingUtcForProposals: boolean;
   marketOperatorId: number;
   maxPrice: number;
   minPrice: number;
   minimumOrderPriceSensitivity: number;
   proposalAmountType: string;
   quantityDecimalPlaces: number;
   quantityDecimal: number;
   quantityDivider: number;
   quantityIncrement: number;
   quantityUnit: string;
   regionId: number;
   regionTimeZone: string;
   serviceName: string;
   showRucRad: boolean;
   useQuarterMinuteProposals: boolean;
   validityDateMinuteSteps: number;
   netPositionDisplay: number;
   showInstrumentAlerts: boolean;
   defaultActionCompany: number;
}

export interface IGipPlanningCalculateRucHour {
  companyId: number;
  companyName: string;
  ruc: number;
  hour: number;
  totalNeedMatch: number;
  instrumentDate: string;
  remainingTransactionCount: number;
  tgs?: number;
}
export interface IGipPlanningCalculateRucDay {
   dateString: string | undefined;
   isOpened: boolean;
   pureRad: number;
   barProgress: number;
   groupTotalNeedMatch: number;
}
export interface IGipPlanningCalculateRuc {
   day1: IGipPlanningCalculateRucDay;
   day2: IGipPlanningCalculateRucDay;
   hourlyRucValues: IGipPlanningCalculateRucHour[];
   rucRange: number;
}
export interface IGipPlanningCalculatedWallValuesNamed {
   [selectedWallKey: string]: IGipPlanningCalculatedWallValues;
}
export interface IGipPlanningCalculatedWallValues {
   maxNetPosition: number;
   radValue: IGipPlanningCalculateRuc;
}
export interface IGipPlanningCalculatedValues {
   maxMcp: number;
   minMcp: number;
   maxDirection: number;
   minDirection: number;
}

export interface IAuctionTypes {
   IsSourceOfMcp: boolean;
   AuctionIdentifier: number;
   Code: string;
   IsActive: boolean;
}

export interface IGipPlanningDynamicGroupDetail {
   id: number;
   name: string;
   type: number;
   enabled: boolean;
   displayOrder: number;
   expression: string;
   expressionParsed: string;
   groupId: number;
   triggerType: number;
   calculationCategory: number;
   errorCount: number;
}
export interface IGipPlanningDynamicGroup {
   id: number;
   name: string;
   type: number;
   enabled: boolean;
   displayOrder: number;
   details: IGipPlanningDynamicGroupDetail[];
}

export interface IGipPlanningSelectedWall {
   widgetId: string;
   dateIndex: number;
   selectedWallKey: string;
   companyId: number;
   portfolioId: number;
   entryType: EIntradayPlanningTab;
   contractKeys: string[];
   isLoaded: boolean;
   loadingState: LoadingStateEnum;
   lastFetchWallData?: number;
}

export interface IGipPlanningSelectedContractDetails {
   widgetId: string;
   companyId: number;
   contractDataKey?: string;
   loadingState: LoadingStateEnum;
}

export interface IGipPlanningTickerInfo {
   contractDataKey?: string;
   periodName?: string;
   message?: string;
   localization?: string;
   parameters?: any;
   tickerType: ETickerType;
   oldValue?: any;
   newValue?: any;
   eventTime: Date;
   uuid: string;
   botId?: number;
}
export interface IContractShortInfo {
   contractDataKey: string;
   contractId: string;
   contractName: string;
   deliveryStart: string;
   deliveryEnd: string;
   periodId: number;
   periodName: string;
   tradingPhaseEnd: string;
   isOpenForTrade: boolean;
   state: EContractState;
   contractType: EContractType;
   displayName: string;
   displayShortName: string;
}
export interface IRucRiskData {
   unitData: number[];
   unitLabel: string[];
}

export interface IGipPlanningSelectedWallNamed {
   [widgetId: string]: IGipPlanningSelectedWall;
}
export interface IGipPlanningSelectedContractDetailsNamed {
   [widgetId: string]: IGipPlanningSelectedContractDetails;
}
export interface IGipPlanningWebSocketStoreModel {
   connectionStatus: GipPlanningWebSocketConnectionStatus;
   lastPongTime: Date;
   heartbeat: boolean;
   userMessage?: IGipPlanningWebSocketUserMessage;
   socketSessionId?: string;
   systemStatus?: ISystemStatus;
   alertRules?: IAlertRule[];
   libraryScripts?: ILibraryScript[];
   contracts: IBoardContract[];
   stateUpdatedStamp: Date;
   listedContractsInfo: IContractShortInfo[];
   powerPlants: IPowerPlant[];
   calculatedValues: IGipPlanningCalculatedValues;
   calculatedWallValues: IGipPlanningCalculatedWallValuesNamed;
   userInfo?: IGipPlanningUserInfo;
   radRiskInfo?: IGipPlanningRadRiskInfo;
   liveSettings?: IGipPlanningLiveSettings;
   lastTradeInfo?: IContractTradeInfo;
   selectedWall: IGipPlanningSelectedWallNamed;
   selectedContractDetails: IGipPlanningSelectedContractDetailsNamed;
   serverTime?: IServerTime;
   dynamicGroups?: IGipPlanningDynamicGroup[];

   proposalLimits: IProposalLimit[];
   rucRiskData: IRucRiskData;
   auctionTypes?: IAuctionTypes[];
   userDefaultBuySellCompany?: UserDefaultBuySellCompany;
   maxAllowedWorkspaceCount?: number;
}
