const tr = {
    general: {
        logout: 'Çıkış Yap',
        docs: "Dokümantasyon",
        notallowedip: `İzin verilmeyen IP'den erişim sağlayamazsınız!`,
        confirm: 'Onayla',
        cancel: 'İptal',
    },
    global: {
        notvalidcode: 'geçersiz kod',
    },
    menu: {
        menu: 'Menü',
        home: 'Anasayfa'
    },
    pages: {
        home: 'Anasayfa',
        settings: 'Ayarlar',
        profile: 'Profil Ayarları'
    },
    settings: {
        darkMode: 'Karanlık Mod',
        layout: 'Düzen'
    },
    login: {
        accountpreferences: 'Hesap Tercihleri',
        welcome: 'Hoş Geldiniz!',
        signInToPortal: "smartPulse Portal'a Giriş Yapın",
        meetUs: 'Bizimle Tanışın!',
        onBoarding: 'smartPulse, IoT altyapısı ile Yapay Zeka algoritmalarını birleştirerek geliştirdiği dijital enerji teknolojileri ile enerji planlaması ve enerji maliyet yönetiminin yolunu şekillendiriyor.',
        joinUs: "smartPulse ailesinin bir üyesi olmak ister misiniz?",
        userName: 'Kullanıcı Adı',
        password: 'Şifre',
        showPasswordTooltip: 'Şifreyi Göster',
        hidePasswordTooltip: 'Şifreyi Gizle',
        login: 'Giriş Yap',
        usernamewrong: "Kullanıcı adı yanlış!",
        passwordwrong: "Şifre yanlış",
        usernameorpasswordwrong: "Kullanıcı Adı veya Şifre hatalı!",
        verifyCaptchaError: "Doğrulama kodu yanlış!",
        validation: {
            userName: 'Kullanıcı adı gerekli',
            password: 'Şifre gerekli'
        },
        centralAccount: "Merkezi Hesap",
        submit: "Gönder",
        rememberMe: "Beni hatırla",
        validate: "Doğrula",
        external: {
            user: "Lütfen giriş yapmak için 'MERKEZİ HESAP' butonunu kullanın",
        }
    },
    contracts: {
        contract: 'Sözleşme',
        contractId: 'Sözleşme ID',
    },
};

export default tr;
