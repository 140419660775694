export const convertToBase64 = (file: File): Promise<string[] | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const base64Content = reader.result as string;

            if (!base64Content) {
                resolve(null);
            } else {
                const fileContents = base64Content.split(',');
                resolve(fileContents);
            }
        };
        reader.onerror = ev => {
            reject(ev);
        };

        reader.readAsDataURL(file);
    });
}

export const buildFileUploadForm = (file: File, extendedKeys?: Record<string, any>): Promise<FormData | null> => {
    return new Promise((resolve) => {
        return convertToBase64(file)
            .then(fileContents => {
                if (fileContents === null || (fileContents.length <= 0)) {
                    throw new Error('Dosya içeriği belirlenemedi!');
                }

                const payloadForm = new FormData();
                payloadForm.append('FileBase64', fileContents[1]);
                payloadForm.append('FileName', file.name);

                if (extendedKeys)
                    Object.entries(extendedKeys).forEach(([key, value]) => {
                        payloadForm.append(key, value);
                    })

                resolve(payloadForm);
            });
    });
}