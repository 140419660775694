export const GipPlanningActionKeys = {
    GIP_PLANNING_TICKER_ADD:'GIP_PLANNING_TICKER_ADD',
    GIP_PLANNING_TICKER_ADD_ARRAY:'GIP_PLANNING_TICKER_ADD_ARRAY',
    GIP_PLANNING_TICKER_CLEAR:'GIP_PLANNING_TICKER_CLEAR',
    GIP_PLANNING_DEPTH_INFO_UPDATE:'GIP_PLANNING_DEPTH_INFO_UPDATE',
    GIP_PLANNING_DEPTH_INFO_START_INTERVAL:'GIP_PLANNING_DEPTH_INFO_START_INTERVAL',
    GIP_PLANNING_DEPTH_INFO_CLEAR_INTERVAL:'GIP_PLANNING_DEPTH_INFO_CLEAR_INTERVAL',
    GIP_PLANNING_DEPTH_INFO_CLEAR:'GIP_PLANNING_DEPTH_INFO_CLEAR',
    GIP_PLANNING_MY_PROPOSALS_UPDATE:'GIP_PLANNING_MY_PROPOSALS_UPDATE',
    GIP_PLANNING_MY_PROPOSALS_CLEAR:'GIP_PLANNING_MY_PROPOSALS_CLEAR',
    GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR:'GIP_PLANNING_MY_PROPOSALS_FROM_KEY_CLEAR',
    GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR:'GIP_PLANNING_DEPTH_INFO_FROM_KEY_CLEAR',
    GIP_PLANNING_TRADES_UPDATE:'GIP_PLANNING_TRADES_UPDATE',
    GIP_PLANNING_TRADES_CLEAR:'GIP_PLANNING_TRADES_CLEAR',
    GIP_PLANNING_TRADES_FROM_KEY_CLEAR:'GIP_PLANNING_TRADES_FROM_KEY_CLEAR',
    GIP_PLANNING_RELRATED_GROUP_PROPOSALS_UPDATE:'GIP_PLANNING_RELRATED_GROUP_PROPOSALS_UPDATE',
    GIP_PLANNING_RELRATED_GROUP_PROPOSALS_CLEAR:'GIP_PLANNING_RELRATED_GROUP_PROPOSALS_CLEAR',
    GIP_PLANNING_RELRATED_GROUP_PROPOSALS_FROM_KEY_CLEAR:'GIP_PLANNING_RELRATED_GROUP_PROPOSALS_FROM_KEY_CLEAR',

    GIP_PLANNING_MYPROPOSAL_INFO_START_INTERVAL:'GIP_PLANNING_MYPROPOSAL_INFO_START_INTERVAL',
    GIP_PLANNING_MYPROPOSAL_CLEAR_INTERVAL:'GIP_PLANNING_MYPROPOSAL_INFO_CLEAR_INTERVAL',

    GIP_PLANNING_RELATED_GROUP_PROPOSALS_START_INTERVAL:'GIP_PLANNING_RELATED_GROUP_PROPOSALS_START_INTERVAL',
    GIP_PLANNING_RELATED_GROUP_PROPOSALS_CLEAR_INTERVAL:'GIP_PLANNING_RELATED_GROUP_PROPOSALS_CLEAR_INTERVAL',

    GIP_PLANNING_TRADES_START_INTERVAL:'GIP_PLANNING_RELATED_TRADES_START_INTERVAL',
    GIP_PLANNING_TRADES_CLEAR_INTERVAL:'GIP_PLANNING_RELATED_TRADES_CLEAR_INTERVAL',

    GIP_PLANNING_TICKER_START_INTERVAL:'GIP_PLANNING_TICKER_START_INTERVAL',
    GIP_PLANNING_TICKER_CLEAR_INTERVAL:'GIP_PLANNING_TICKER_CLEAR_INTERVAL',
    
}