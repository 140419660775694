import { useState, useEffect, useRef } from "react";
import {
   Box,
   Tooltip,
   Divider,
   IconButton,
   Typography,
   ListItemText,
   ListItemAvatar,
   ListItemButton,
   Switch,
   FormControlLabel,
   List,
   ListSubheader,
   Badge,
   Button,
   Stack,
   Popover,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import * as NotificationService from "../../../services/notifications/notifications.service";
import * as Utils from "../../../utils";
import { Site } from "../../../utils/site";
import MenuPopover from "../../../components/MenuPopover";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Iconify from "../../../components/Iconify";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { MarkNotificationReadIcon, MoreHorizIcon } from "src/old/src/components/icons";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import Scrollbar from "src/components/Scrollbar";
import { INotificationModel } from "src/models/notifications.model";
import NotificationSounds from "./NotificationSounds";

enum NotificationBrodcastEventType {
   readAllNotification = "READ_ALL_NOTIFICATION",
   readNotification = "READ_NOTIFICATION",
   openSound = "OPEN_SOUND",
}

export enum ENotificationTypes {
   smartbotnotification = "smartbotnotification",
   excelimportcomplete = "excelimportcompletet",
   questionchangenotification = "questionchangenotification",
   executecontractscomplete = "executecontractscomplete",
   executecontractsfailed = "executecontractsfailed",
}

const intervalConfig = import.meta.env.VITE_USER_NOTIFICATIONS_INTERVAL;
const listItemButtonProps = { py: 1.5, px: 2.5, mt: "1px" };

function NotificationsPopover() {
   const { formatMessage, formatDate } = useIntl();

   const [notifications, setNotifications] = useState<INotificationModel[]>([]);
   const [open, setOpen] = useState<HTMLElement | null>(null);
   const [soundOpen, setSoundOpen] = useState<boolean>(() => {
      const saved = sessionStorage.getItem("soundOpen");
      return saved ? JSON.parse(saved) : true;
   });
   const [soundAnchorEl, setSoundAnchorEl] = useState<null | HTMLElement>(null);

   const lastNotificationId = useRef<string | null>(null);
   const notificationChannelRef = useRef(new BroadcastChannel("notification-channel"));
   const pushNotificationEnabled = (Site?.ServiceWorker?.Subscription || null) !== null;
   const unreadedcount = notifications.filter((notification) => notification.Status === 0).length;

   useEffect(() => {
      let intervalValue: number | undefined;

      if (intervalConfig) {
         intervalValue = Number(intervalConfig);

         if (intervalValue === -1) {
            intervalValue = undefined;
            console.warn("User notifications auto refresh is disabled by dev env configuration!");
         }
      }

      const getUserNotifications = async (isPlaySound = true) => {

         const response = await NotificationService.getNotifications(lastNotificationId.current);

         if (!response || !response.Success) return;

         const newNotifications = response.Data?.Notifications || [];

         if (newNotifications.length <= 0) return;

         setNotifications((prevNotifications) => {
            const combinedNotifications = [...newNotifications, ...prevNotifications];
          
            const uniqueNotifications = combinedNotifications.filter(
              (notification, index, self) => index === self.findIndex((n) => n.Id === notification.Id)
            );
          
            return uniqueNotifications.slice(0, 50);
         });

         lastNotificationId.current = newNotifications[0].Id;

         const notification = newNotifications.find(x => x.SoundPath);

         if (soundOpen && isPlaySound && notification && notification.SoundPath) {
            const currentTime = Date.now();
            const lastPlayTime = localStorage.getItem('lastSoundPlayTime');
      
            if (lastPlayTime && currentTime - Number(lastPlayTime) < 5000) {
               return; 
            }
            localStorage.setItem('lastSoundPlayTime', currentTime.toString());
            playSound(notification.SoundPath);
         }
      };

      getUserNotifications(false);

      const intervalId = setInterval(getUserNotifications, intervalValue ?? 5000);
     
      return () => clearInterval(intervalId);
   }, [soundOpen]);

   useEffect(() => {
      if (!notificationChannelRef.current) return;

      notificationChannelRef.current.onmessage = (event) => {
         if (event.data.type === NotificationBrodcastEventType.readAllNotification) {
            setNotifications(prev => prev.map(x => ({...x, Status: 1})));
         } else if (event.data.type === NotificationBrodcastEventType.readNotification) {
            setNotifications(prev => prev.map((x) => x.Id === event.data.notificationId ? {...x, Status: 1} : x));
         } else if (event.data.type === NotificationBrodcastEventType.openSound) {
            setSoundOpen(event.data.isOpen);
         }
      };
      
      return () => {
         notificationChannelRef.current.close();
         sessionStorage.removeItem("soundOpen");
      };
   }, [notificationChannelRef.current]);

   const readNotifications = async (notificationId: string) => {
      try {
         await NotificationService.readNotifications(notificationId);
         setNotifications(prev => prev.map((x) => x.Id === notificationId ? {...x, Status: 1} : x));
         notificationChannelRef.current?.postMessage({ 
            type: NotificationBrodcastEventType.readNotification, 
            notificationId: notificationId
         });
      } catch (error) {
         Utils.showErrorMessage(error?.toString() ?? "Error");
      }
   };

   const readAll = async () => {
      try {
         await NotificationService.readAll();
         setNotifications(prev => prev.map(x => ({...x, Status: 1})));
         notificationChannelRef.current?.postMessage({ type: NotificationBrodcastEventType.readAllNotification });
      } catch (error) {
         Utils.showErrorMessage(error?.toString() ?? "Error");
      }
   };

   const getMessageForDescription = (desc: string) => {
      try {
         const res = JSON.parse(desc);
         return formatMessage(
            { id: res.Text },
            { startDate: formatDate(res.Params[0]), endDate: formatDate(res.Params[1]) }
         );
      } catch {
         return desc;
      }
   };

   const playSound = (path: string) => {
      const audio = new Audio(`/assets/sounds/${path}`);
      audio.play();
   };

   return (
      <>
         <Tooltip title={formatMessage({ id: "global.notifications" })}>
            <IconButton
               color={open ? "primary" : "default"}
               onClick={(event) => setOpen(event.currentTarget)}
               sx={{ width: 40, height: 40 }}
            >
               <Badge badgeContent={unreadedcount} color="error">
                  <NotificationsIcon width={20} height={20} />
               </Badge>
            </IconButton>
         </Tooltip>

         <MenuPopover
            arrow="top-center"
            open={Boolean(open)}
            anchorEl={open}
            onClose={() => setOpen(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ width: 400, p: 0, mt: 4 }}
         >
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                  px: 2,
                  width: "100%",
               }}
            >
               <Button
                  sx={{ fontSize: 12 }}
                  onClick={readAll}
                  startIcon={<MarkNotificationReadIcon color="primary" />}
                  variant="outlined"
               >
                  {formatMessage({ id: "global.readall.upper" })}
               </Button>
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                  <FormControlLabel
                     label={formatMessage({ id: "global.pushnotifications" })}
                     labelPlacement={"end"}
                     style={{ margin: 0 }}
                     control={
                        <Switch
                           size="small"
                           color="primary"
                           defaultChecked={pushNotificationEnabled}
                           onChange={(event) => {
                              if (event.target.checked) {
                                 Site?.ServiceWorker?.RegisterDevice();
                              } else {
                                 Site.ServiceWorker.RevokePermission();
                              }
                           }}
                        />
                     }
                  />
                  <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                     <Tooltip title={soundOpen ? formatMessage({ id: "global.soundOn" }) : formatMessage({ id: "global.soundOff" })}>
                        <IconButton 
                           sx={{ width: 40, height: 40 }}
                           onClick={() => {
                              setSoundOpen(!soundOpen);
                              sessionStorage.setItem("soundOpen", JSON.stringify(!soundOpen));
                              notificationChannelRef.current?.postMessage({ 
                                 type: NotificationBrodcastEventType.openSound, 
                                 isOpen: !soundOpen,
                              });
                           }} 
                        >
                           {soundOpen ? (
                              <VolumeUpIcon width={20} height={20} />
                           ) : (
                              <VolumeOffIcon width={20} height={20} />
                           )}
                        </IconButton>
                     </Tooltip>
                     <Tooltip title={formatMessage({ id: "global.manageSounds" })}>
                        <IconButton
                           onClick={(event) => setSoundAnchorEl(event.currentTarget)}
                           sx={{ width: 40, height: 40 }}
                        >
                           <MoreHorizIcon width={20} height={20} sx={{ rotate: "90deg" }} />
                        </IconButton>
                     </Tooltip>

                     <Popover
                        id={soundAnchorEl ? "simple-popover" : undefined}
                        open={Boolean(soundAnchorEl)}
                        anchorEl={soundAnchorEl}
                        onClose={() => setSoundAnchorEl(null)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                     >
                        <NotificationSounds />
                     </Popover>
                  </Box>
               </Box>
            </Box>

            <Divider sx={{ borderStyle: "dashed" }} />

            <Scrollbar sx={{ maxHeight: 800 }}>
               <List style={{ maxHeight: "800px" }} sx={{ height: { xs: 340, sm: "auto" } }}>
                  <List
                     disablePadding
                     subheader={
                        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "body1" }}>
                           {formatMessage({ id: "global.new" })}
                        </ListSubheader>
                     }
                  >
                     {(notifications || [])
                        .filter((notification) => notification.Status === 0)
                        .map((notification) => (
                           <ListItemButton
                              sx={listItemButtonProps}
                              onClick={() => {
                                 readNotifications(notification.Id);
                              }}
                              key={notification.Id}
                           >
                              <ListItemAvatar>
                                 <NotificationsActiveIcon />
                              </ListItemAvatar>

                              <ListItemText
                                 primary={
                                    <Typography variant="body2"  whiteSpace="pre-line">
                                       {getMessageForDescription(notification.Description)}
                                    </Typography>
                                 }
                                 secondary={
                                    <Typography
                                       variant="body2"
                                       sx={{
                                          mt: 0.5,
                                          display: "flex",
                                          alignItems: "center",
                                          color: "text.disabled",
                                       }}
                                    >
                                       <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                                       {notification.CreateDate}
                                    </Typography>
                                 }
                              />
                           </ListItemButton>
                        ))}
                  </List>

                  {unreadedcount === 0 && (
                     <Stack direction="row" justifyContent={"center"} alignItems={"center"} spacing={1} padding={1}>
                        <NotificationsPausedIcon sx={{ textAlign: "center" }} />
                        <Typography variant={"body2"}>
                           <FormattedMessage id="global.nonewnotification" defaultMessage="No New Notifications." />
                        </Typography>
                     </Stack>
                  )}

                  {notifications.length > 0 && (
                     <>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <List
                           disablePadding
                           subheader={
                              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: "body1" }}>
                                 {formatMessage({ id: "global.beforethat" })}
                              </ListSubheader>
                           }
                        >
                           {(notifications || [])
                              .filter((notification) => notification.Status === 1)
                              .map((notification) => (
                                 <ListItemButton sx={listItemButtonProps} key={notification.Id}>
                                    <ListItemAvatar>
                                       <NotificationsNoneIcon />
                                    </ListItemAvatar>

                                    <ListItemText
                                       primary={
                                          <Typography variant="body2">
                                             {getMessageForDescription(notification.Description)}
                                          </Typography>
                                       }
                                       secondary={
                                          <Typography
                                             variant="caption"
                                             sx={{
                                                mt: 0.5,
                                                display: "flex",
                                                alignItems: "center",
                                                color: "text.disabled",
                                             }}
                                          >
                                             <Iconify
                                                icon="eva:clock-outline"
                                                sx={{ mr: 0.5, width: 16, height: 16 }}
                                             />
                                             {notification.CreateDate}
                                          </Typography>
                                       }
                                    />
                                 </ListItemButton>
                              ))}
                        </List>{" "}
                     </>
                  )}
               </List>
            </Scrollbar>
         </MenuPopover>
      </>
   );
}

export default NotificationsPopover;
