import axios, {AxiosInstance} from 'axios';
import {
    DeleteConsumptionFacilityDto,
    ExportExcelModel,
    GetConsumptionForecastsQueryModel,
    GetMultipleConsumptionForecastsQueryByFacilityDto,
} from 'src/models/consumption-forecast.model';
import $ from 'jquery';

const nodeEnv = import.meta.env.MODE;
export const isProduction = nodeEnv === "production";
export const consumptionForecastServiceName : string = "consumption-forecast-service";

const instance: AxiosInstance = axios.create({
    baseURL: isProduction ? '/api/' : '/s/api/',
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-SmartPulse-Service',
        "X-SmartPulse-Service": consumptionForecastServiceName,
    },
});

instance.interceptors.request.use((config) => {
    if (config.method === 'get' || config.method === 'post') {
        $('#loader').addClass('whirl');
    }
    return config;
});

instance.interceptors.response.use(
    (response) => {
        $('#loader').removeClass('whirl');
        return response;
    },
    (error) => {
        $('#loader').removeClass('whirl');
        return Promise.reject(error);
    }
);

export const getServiceUrl = (path: string) => {
    const currentURL = new URL(window.location.href);
    return path.startsWith("http://") || path.startsWith("https://")
        ? path
        : new URL(isProduction
            ? path
            : `/s${path.startsWith("/") ? "" : "/"}${path}`, currentURL)
            .toString();
};


//#region Consumption Facility
export const getCountries = async () => {
    return instance
        .get('/consumption-facility/countries')
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getCities = async (countryCode: string) => {
    return instance
        .get(`/consumption-facility/cities/${countryCode}`)
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getCounties = async (cityCode: string) => {
    return instance
        .get(`/consumption-facility/districts/${cityCode}`)
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const createFacility = async (facility: any) => {
    return instance
        .post(`/consumption-facility`, facility)
        .then((response) => response)
        .catch((error) => error);
};

export const getAllFacilities = async (userId) => {
    return instance
        .get(`/consumption-facility/get-all/${userId}`)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getAllActiveFacilities = async (req, userId) => {
    return instance
        .post(`/consumption-facility/get-all-actives/${userId}`, req)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getAllCompanyFacilities = (companyId: number) => {
    return instance
        .post(`consumption-facility/company/${companyId}`, {})
        .then((response) => response?.data)
        .catch((error) => console.log('api error', error));
};

export const getAllGroupFacilities = (groupId: number) => {
    return instance
        .post(`consumption-facility/group/${groupId}`, {})
        .then((response) => response?.data)
        .catch((error) => console.log('api error', error));
};

export const updateFacility = async (facility: any) => {
    return instance
        .put(`/consumption-facility`, facility)
        .then((response) => response)
        .catch((error) => error);
};

export const deleteFacility = async (facility: DeleteConsumptionFacilityDto) => {
    try {
        const response = await instance.post(`/consumption-facility/delete`, facility);
        return response?.data;
    } catch (error) {
        console.log('api error', error);
    }
};
//#endregion Consumption Facility

//#region Consumption Forecast
export const createConsumptionForecast = async (
    consumptionForecast: any,
    providerKey: string,
    unitType: string,
    unitNo: string
) => {
    return instance
        .post(`/consumption-forecast/${providerKey}/${unitType}/${unitNo}`, consumptionForecast)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const createConsumptionForecastMultiples = async (data: any) => {
    return instance
        .post(`/consumption-forecast/multiples`, data)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getConsumptionForecast = async (query: GetConsumptionForecastsQueryModel) => {
    return instance
        .get(`/consumption-forecast/`, {params: query})
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

// ! deprecated
export const getConsumptionForecastWithLock = async (query: GetConsumptionForecastsQueryModel) => {
    return instance
        .get(`/consumption-forecast/lock`, {params: query})
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const createConsumptionMeter = async (meter: any) => {
    return instance
        .post(`/consumption-facility/meters`, meter)
        .then((response) => response)
        .catch((error) => error);
};

export const updateConsumptionMeter = async (meter: any) => {
    return instance
        .put(`/consumption-facility/meters`, meter)
        .then((response) => response)
        .catch((error) => error);
};

export const getAllMetersByFacilityId = async (facilityId: number) => {
    return instance
        .post(`/consumption-facility/meters/${facilityId}`, {})
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const deleteFacilityCounter = async (counterId: number, etso: string) => {
    try {
        const response = await instance.delete(`/consumption-facility/meters/${counterId}/${etso}`);
        return response?.data;
    } catch (error) {
        console.log('api error', error);
    }
};

export const getContractHistory = async (request) => {
    return instance
        .post(`/consumption-forecast/contract-history`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getAllConsumptionForecastLockAndReferance = async (request) => {
    return instance
        .post(`/consumption-forecast/get-all-consumption-forecast-lock-and-referance`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getMultipleConsumptionForecastByFacility = async (
    query: GetMultipleConsumptionForecastsQueryByFacilityDto
) => {
    return instance
        .post(`/consumption-forecast/get-multiple`, query)
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getMultipleConsumptionForecastsByFacilityWithLockAsync = async (
    query: GetMultipleConsumptionForecastsQueryByFacilityDto
) => {
    return instance
        .post(`/consumption-forecast/lock-multiple`, query)
        .then((response) => response)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const exportForecastTemplate = async (query: ExportExcelModel) => {
    const callUrl = getServiceUrl("/api/consumption-forecast/excel-export");
    fetch(callUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "X-SmartPulse-Service": consumptionForecastServiceName
        },
        body: JSON.stringify(query),
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `SmartPulse_ForecastTemplate_${
                    new Date()
                        .toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })
                        .replace(/\//g, '-') +
                    '_' +
                    new Date()
                        .toLocaleTimeString('en-US', {
                            hour12: false,
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        .replace(/:/g, '-')
                }.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        });
};

export const getUploadedFiles = async (request) => {
    try {
        const response = await instance.post(`/consumption-forecast/get-uploaded-files`, request);
        return response?.data || [];
    } catch (error) {
        console.error("failed to fetch data from 'GetUploadedFiles' service", error);
    }
};

export const getTotalFinalForecast = async (request) => {
    return instance
        .post(`/consumption-forecast/get-total-final-forecast`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getProviders = async (request) => {
    return instance
        .post(`/consumption-forecast/get-consumption-providers`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getFacilityGopSettings = async (request) => {
    return instance
        .post(`/consumption-forecast/facility-gop-settings`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const getFacilityGipSettings = async (request) => {
    return instance
        .post(`/consumption-forecast/facility-gip-settings`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const saveFacilityGopSettings = async (request) => {
    return instance
        .post(`/consumption-forecast/save-facility-gop-settings`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const saveFacilityGipSettings = async (request) => {
    return instance
        .post(`/consumption-forecast/save-facility-gip-settings`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

// NEW METHODS
export const getConsumptionForecasts = async (request) => {
    return instance
        .post(`/consumption-forecast/get-consumption-forecast`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};

export const saveConsumptionForecasts = async (request) => {
    return instance
        .post(`/consumption-forecast/save-consumption-forecasts`, request)
        .then((response) => response?.data)
        .catch((error) => {
            console.log('api error', error);
        });
};
// END NEW METHODS
//#endregion Consumption Forecast
