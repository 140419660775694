import ApplicationDefinitions, { IApplicationDefinition, ExperimentalAppId } from "./applicationDefinitions";
import BatchPredictionRoundedIcon from '@mui/icons-material/BatchPredictionRounded';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CodeIcon from '@mui/icons-material/Code';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {DashboardIcon, PaymentIcon, ScienceIcon} from "../../components/icons";

export interface IMenuItem {
    type: "node" | "application",
    platform: "mobile" | "web" | "all",
    textResourceId: string,
    tooltipResourceId: string,
    flag: "normal" | "newrelease" | "newfeature" | "preview",
    adminonly?: boolean,
    iconName?: any,
    details: IMenuItem[] | IApplicationDefinition
    applicationId?: number
}

export const PredictionManagementMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.dataandpredictionmanagement",
    tooltipResourceId: "menu.group.title.dataandpredictionmanagement",
    iconName: <BatchPredictionRoundedIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.productionforecasts",
            tooltipResourceId: "menu.app.title.productionforecasts",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2001) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.productionforecasts.new",
            tooltipResourceId: "menu.app.text.productionforecasts.new",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2111) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumptionforecasts",
            tooltipResourceId: "menu.app.title.consumptionforecasts",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 10027) || {} as IApplicationDefinition)
        },

        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumptionfacility",
            tooltipResourceId: "menu.app.title.consumptionfacility",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 10028) || {} as IApplicationDefinition)
        },

        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumptionfacilitylock",
            tooltipResourceId: "menu.app.title.consumptionfacilitylock",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 10029) || {} as IApplicationDefinition)
        },

        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.planthourlydataentry",
            tooltipResourceId: "menu.app.title.planthourlydataentry",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2009) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumptionfacilityquestioninput",
            tooltipResourceId: "menu.app.text.consumptionfacilityquestioninput",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 18004) || {} as IApplicationDefinition)
        },

        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.plantforecastcomparison",
            tooltipResourceId: "menu.app.title.plantforecastcomparison",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2004) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.servicecontrolresults",
            tooltipResourceId: "menu.app.title.servicecontrolresults",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2005) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.expertprediction",
            tooltipResourceId: "menu.app.title.expertprediction",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2006) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.turbinesettings",
            tooltipResourceId: "menu.app.title.turbinesettings",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2007) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.predictionreliability",
            tooltipResourceId: "menu.app.title.predictionreliability",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2010) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.turbinefault",
            tooltipResourceId: "menu.app.title.turbinefault",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2008) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.powerplantdatadistribution",
            tooltipResourceId: "menu.app.title.powerplantdatadistribution",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2011) || {} as IApplicationDefinition)
        }
    ]
};

export const MarketDeclerationsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.marketdeclerations",
    tooltipResourceId: "menu.group.title.marketdeclerations",
    iconName: <NotificationImportantRoundedIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dayaheadnomination",
            tooltipResourceId: "menu.app.title.dayaheadnomination",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2101) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dayaheadplanning",
            tooltipResourceId: "menu.app.title.dayaheadplanning",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2102) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.bilateralagreement",
            tooltipResourceId: "menu.app.title.bilateralagreement",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2103) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumerforecastupdate",
            tooltipResourceId: "menu.app.title.consumerforecastupdate",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2104) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.bilateralagreementupdate",
            tooltipResourceId: "menu.app.title.bilateralagreementupdate",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2105) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.extraposition",
            tooltipResourceId: "menu.app.title.extraposition",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2107) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.counterpartyba",
            tooltipResourceId: "menu.app.title.counterpartyba",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2106) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.orderauctionmanagement",
            tooltipResourceId: "menu.app.title.orderauctionmanagement",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2108) || {} as IApplicationDefinition)
        }
        ,
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.automateddeclarationsettings",
            tooltipResourceId: "menu.app.title.automateddeclarationsettings",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2109) || {} as IApplicationDefinition)
        }
    ]
};

export const BilateralAgreementManagement: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.bamanagement",
    tooltipResourceId: "menu.group.title.bamanagement",
    iconName: <HandshakeIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.baagreementcontract",
            tooltipResourceId: "menu.app.title.baagreementcontract",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 5001) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.baagreementnomination",
            tooltipResourceId: "menu.app.title.baagreementnomination",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 5002) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.bamarketparticipants",
            tooltipResourceId: "menu.app.title.bamarketparticipants",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 5003) || {} as IApplicationDefinition)
        }
    ]
};

export const SystemOperatorDeclarationsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.systemoperatordeclarations",
    tooltipResourceId: "menu.group.title.systemoperatordeclarations",
    iconName: <NotificationImportantIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.eaknotification",
            tooltipResourceId: "menu.app.title.eaknotification",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2201) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.kgupnotification",
            tooltipResourceId: "menu.app.title.kgupnotification",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2202) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.mkudsending",
            tooltipResourceId: "menu.app.title.mkudsending",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2205) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dgpoffer",
            tooltipResourceId: "menu.app.title.dgpoffer",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2204) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dgpinstructiontracing",
            tooltipResourceId: "menu.app.title.dgpinstructiontracing",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 5101) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.bpmconsolidatednotification",
            tooltipResourceId: "menu.app.title.bpmconsolidatednotification",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 5100) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.pfkoffer",
            tooltipResourceId: "menu.app.title.pfkoffer",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2206) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.pfknomination",
            tooltipResourceId: "menu.app.title.pfknomination",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2207) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.sfkoffer",
            tooltipResourceId: "menu.app.title.sfkoffer",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2208) || {} as IApplicationDefinition)
        }
    ]
};

export const AutomaticNotificationsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.app.text.automaticnotification",
    tooltipResourceId: "menu.app.title.automaticnotification",
    iconName: <AccessAlarmIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.automaticnotificationpowerplant",
            tooltipResourceId: "menu.app.title.automaticnotificationpowerplant",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2203) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.automaticnotificationcompany",
            tooltipResourceId: "menu.app.title.automaticnotificationcompany",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2209) || {} as IApplicationDefinition)
        }
    ]
};

export const MarketVariablesMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.marketvariables",
    tooltipResourceId: "menu.group.title.marketvariables",
    iconName: <EqualizerIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.smpandmtpforecast",
            tooltipResourceId: "menu.app.title.smpandmtpforecast",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2303) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.marketdata",
            tooltipResourceId: "menu.app.title.marketdata",
            iconName: "",
            flag: "preview",
            details: (ApplicationDefinitions.find((x) => x.id === 2304) || {} as IApplicationDefinition)
        }
    ]
};

export const ProposalPlanningMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.proposalplanning",
    tooltipResourceId: "menu.group.title.proposalplanning",
    iconName: <LocalGroceryStoreIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.intradayplanning",
            tooltipResourceId: "menu.app.title.intradayplanning",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2401) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.intradayplanning_new",
            tooltipResourceId: "menu.app.title.intradayplanning_new",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2404) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.IntradayTransactionReport",
            tooltipResourceId: "menu.app.title.IntradayTransactionReport",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2406) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.quickproposal",
            tooltipResourceId: "menu.app.title.quickproposal",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2402) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.adminintraday",
            tooltipResourceId: "menu.app.title.adminintraday",
            iconName: "",
            flag: "normal",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 2403) || {} as IApplicationDefinition)
        }
    ]
};

export const SmartBotMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.smartbot",
    tooltipResourceId: "menu.group.title.smartbot",
    iconName: <CodeIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.tradebots",
            tooltipResourceId: "menu.app.title.tradebots",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2501) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.generationbots",
            tooltipResourceId: "menu.app.title.generationbots",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2906) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.consumptionbots",
            tooltipResourceId: "menu.app.title.consumptionbots",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 18006) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.operationbots",
            tooltipResourceId: "menu.app.title.operationbots",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2503) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.console",
            tooltipResourceId: "menu.app.title.console",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2502) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.library",
            tooltipResourceId: "menu.app.title.library",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2907) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.botsuperviser",
            tooltipResourceId: "menu.app.title.botsuperviser",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 18007) || {} as IApplicationDefinition)
        }
    ]
};

export const AdminPanelsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.adminpanels",
    tooltipResourceId: "menu.group.title.adminpanels",
    iconName: <CodeIcon />,
    flag: "normal",
    applicationId: 0,
    adminonly: true,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.administrativebots",
            tooltipResourceId: "menu.app.title.administrativebots",
            iconName: "",
            flag: "normal",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 4001) || {} as IApplicationDefinition)
        }
    ]
};

export const ContractsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.contracts",
    tooltipResourceId: "menu.group.title.contracts",
    iconName: <BusinessCenterIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.contractitems",
            tooltipResourceId: "menu.app.title.contractitems",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3005) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.product",
            tooltipResourceId: "menu.app.title.product",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2911) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.contracts",
            tooltipResourceId: "menu.app.title.contracts",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2912) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.executecontracts",
            tooltipResourceId: "menu.app.title.executecontracts",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2913) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.contractresults",
            tooltipResourceId: "menu.app.title.contractresults",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2914) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.exceltemplates",
            tooltipResourceId: "menu.app.title.exceltemplates",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2915) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.contractdatamanagement",
            tooltipResourceId: "menu.app.title.contractdatamanagement",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3006) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.contractimportdata",
            tooltipResourceId: "menu.app.title.contractimportdata",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3007) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.definecompanies",
            tooltipResourceId: "menu.app.title.definecompanies",
            iconName: "",
            flag: "newrelease",
            details: (ApplicationDefinitions.find((x) => x.id === 3008) || {} as IApplicationDefinition)
        }
    ]
};

export const KrsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.krsoperations",
    tooltipResourceId: "menu.group.title.krsoperations",
    iconName: <TrackChangesIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.fdpprevision",
            tooltipResourceId: "menu.app.title.fdpprevision",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2601) || {} as IApplicationDefinition)
        },
        // {
        //     platform: "web",
        //     type: "application",
        //     textResourceId: "menu.app.text.intradayandkrsbot",
        //     tooltipResourceId: "menu.app.title.intradayandkrsbot",
        //     iconName: "",
        //     flag: "normal",
        //     details: (ApplicationDefinitions.find((x) => x.id === 2602) || {} as IApplicationDefinition)
        // },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.intradayandkrsbotNew",
            tooltipResourceId: "menu.app.title.intradayandkrsbotNew",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2603) || {} as IApplicationDefinition)
        }
    ]
};

export const CashFlowMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.cashflow",
    tooltipResourceId: "menu.group.title.cashflow",
    iconName: <PaymentIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.marketpaymentschedule",
            tooltipResourceId: "menu.app.title.marketpaymentschedule",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3001) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.marketpaymentpercentage",
            tooltipResourceId: "menu.app.title.marketpaymentpercentage",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3002) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.marketotherpayments",
            tooltipResourceId: "menu.app.title.marketotherpayments",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3003) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.marketpaymentflow",
            tooltipResourceId: "menu.app.title.marketpaymentflow",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 3004) || {} as IApplicationDefinition)
        }
    ]
};

export const ReportsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.reports",
    tooltipResourceId: "menu.group.title.reports",
    iconName: <PieChartRoundedIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dynamicreports",
            tooltipResourceId: "menu.app.title.dynamicreports",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2701) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dayaheadposition",
            tooltipResourceId: "menu.app.title.dayaheadposition",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2705) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.systemlogs",
            tooltipResourceId: "menu.app.title.systemlogs",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2706) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.scheduledtasks",
            tooltipResourceId: "menu.app.title.scheduledtasks",
            iconName: "",
            flag: "normal",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 2707) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.planttracking",
            tooltipResourceId: "menu.app.title.planttracking",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2920) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.dataterminal",
            tooltipResourceId: "menu.app.title.dataterminal",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2921) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.pfkfinalobligation",
            tooltipResourceId: "menu.app.title.pfkfinalobligation",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2710) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.sfkfinalobligation",
            tooltipResourceId: "menu.app.title.sfkfinalobligation",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2711) || {} as IApplicationDefinition)
        }
    ]
};

export const SettingsMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.settings",
    tooltipResourceId: "menu.group.title.settings",
    iconName: <SettingsRoundedIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.pricesetdefinition",
            tooltipResourceId: "menu.app.title.pricesetdefinition",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2901) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.plantlimitdefinition",
            tooltipResourceId: "menu.app.title.plantlimitdefinition",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2902) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.intradayproposallimit",
            tooltipResourceId: "menu.app.title.intradayproposallimit",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2405) || {} as IApplicationDefinition)
        }, {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.othersettings",
            tooltipResourceId: "menu.app.title.othersettings",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2904) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.meterdefinition",
            tooltipResourceId: "menu.app.title.meterdefinition",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2905) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.portfoliomanagement",
            tooltipResourceId: "menu.app.title.portfoliomanagement",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2925) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.grouplicensing",
            tooltipResourceId: "menu.app.title.grouplicensing",
            iconName: "",
            flag: "normal",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 2908) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.usermanagement",
            tooltipResourceId: "menu.app.title.usermanagement",
            iconName: "",
            flag: "normal",
            adminonly: false,
            details: (ApplicationDefinitions.find((x) => x.id === 2910) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.groupmanagement",
            tooltipResourceId: "menu.app.title.groupmanagement",
            iconName: "",
            flag: "normal",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 2916) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.servicecontrol",
            tooltipResourceId: "menu.app.title.servicecontrol",
            iconName: "",
            flag: "newrelease",
            adminonly: true,
            details: (ApplicationDefinitions.find((x) => x.id === 2919) || {} as IApplicationDefinition)
        },
        {
            platform: "web",
            type: "application",
            textResourceId: "ftp.client",
            tooltipResourceId: "ftp.client",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === 2922) || {} as IApplicationDefinition)
        }
    ]
};

export const ErpMenu: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.erp",
    tooltipResourceId: "menu.group.title.erp",
    iconName: <LinkRoundedIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.externalInvoice",
            tooltipResourceId: "menu.app.title.externalInvoice",
            iconName: "",
            flag: "newrelease",
            details: (ApplicationDefinitions.find((x) => x.id === 2923) || {} as IApplicationDefinition)
        }
    ]
}

export const MobileMenu: IMenuItem = {
    platform: "mobile",
    type: "node",
    textResourceId: "menu.group.text.mobile",
    tooltipResourceId: "menu.group.text.mobile",
    iconName: "Mobile",
    flag: "normal",
    details: [
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.market",
            "tooltipResourceId": "menu.app.text.market",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10001) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.bpm",
            "tooltipResourceId": "menu.app.text.bpm",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10002) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.idm",
            "tooltipResourceId": "menu.app.text.idm",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10003) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.idmtransaction",
            "tooltipResourceId": "menu.app.text.idmtransaction",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10004) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.monitor",
            "tooltipResourceId": "menu.app.text.monitor",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10005) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.report",
            "tooltipResourceId": "menu.app.text.report",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10006) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.forecastentry",
            "tooltipResourceId": "menu.app.text.forecastentry",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10007) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.basicmonitor",
            "tooltipResourceId": "menu.app.text.basicmonitor",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10008) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.dataentry",
            "tooltipResourceId": "menu.app.text.mobile.dataentry",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10009) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.dashboard",
            "tooltipResourceId": "menu.app.text.mobile.dashboard",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10010) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.managementreports",
            "tooltipResourceId": "menu.app.text.mobile.managementreports",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10011) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.forecastcomparison",
            "tooltipResourceId": "menu.app.text.mobile.forecastcomparison",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10012) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.energystatus",
            "tooltipResourceId": "menu.app.text.mobile.energystatus",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10013) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.consumptionforecast",
            "tooltipResourceId": "menu.app.text.mobile.consumptionforecast",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10026) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.eaknotification",
            "tooltipResourceId": "menu.app.text.mobile.eaknotification",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 18001) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.servicecontrolresults",
            "tooltipResourceId": "menu.app.text.mobile.servicecontrolresults",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 18002) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.bpmconsolidatednotification",
            "tooltipResourceId": "menu.app.text.mobile.bpmconsolidatednotification",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 18003) || {} as IApplicationDefinition)
        },
        {
            "platform": "mobile",
            "type": "application",
            "textResourceId": "menu.app.text.mobile.extraposition",
            "tooltipResourceId": "menu.app.text.mobile.extraposition",
            "iconName": "",
            "flag": "normal",
            "details": (ApplicationDefinitions.find((x) => x.id === 10014) || {} as IApplicationDefinition)
        }
    ]
};

export const Experimental: IMenuItem = {
    platform: "web",
    type: "node",
    textResourceId: "menu.group.text.experimental",
    tooltipResourceId: "menu.group.title.experimental",
    iconName: <ScienceIcon />,
    flag: "normal",
    applicationId: 0,
    details: [
        {
            platform: "web",
            type: "application",
            textResourceId: "menu.app.text.experimental.main",
            tooltipResourceId: "menu.app.title.experimental.main",
            iconName: "",
            flag: "normal",
            details: (ApplicationDefinitions.find((x) => x.id === ExperimentalAppId) || {} as IApplicationDefinition)
        }
    ]
};

export const MasterMenu: IMenuItem[] = [
    {
        platform: "web",
        type: "application",
        applicationId: 1001,
        textResourceId: "menu.app.text.homepage",
        tooltipResourceId: "menu.app.title.homepage",
        iconName: "Home",
        flag: "normal",
        details: (ApplicationDefinitions.find((x) => x.id === 1001) || {} as IApplicationDefinition)
    },
    {
        platform: "web",
        type: "application",
        applicationId: 1002,
        textResourceId: "menu.app.text.dashboard",
        tooltipResourceId: "menu.app.title.dashboard",
        iconName: <DashboardIcon />,
        flag: "normal",
        details: (ApplicationDefinitions.find((x) => x.id === 1002) || {} as IApplicationDefinition)
    },
    PredictionManagementMenu,
    MarketDeclerationsMenu,
    BilateralAgreementManagement,
    SystemOperatorDeclarationsMenu,
    AutomaticNotificationsMenu,
    MarketVariablesMenu,
    ProposalPlanningMenu,
    SmartBotMenu,
    AdminPanelsMenu,
    ContractsMenu,
    KrsMenu,
    CashFlowMenu,
    ErpMenu,
    ReportsMenu,
    SettingsMenu,
    MobileMenu,
    Experimental
];

export default MasterMenu;