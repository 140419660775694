// routes
import Router from "./routes";

// toastr
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/settings";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";

import { LicenseManager } from "@ag-grid-enterprise/core";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";

import { Constants } from "./constants";

import { ModuleRegistry } from "@ag-grid-community/core";
import { IntlProvider } from "react-intl";
import { useEffect, useState } from "react";

import localeData from "./old/src/locales/data.json";
import { ConfirmDialogProvider } from "./components/shared/ConfirmDialog";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Site } from "./utils/site";
import "../src/old/src/pages/styles.css";
import "../public/static/css/vendor.bundle.css";
import { UserCultureProvider } from "./contexts/UserCultureContext";
import { ErrorBoundary } from "react-error-boundary";
import CommonFallback from "./components/Error/CommonFallback";

ModuleRegistry.registerModules([
   ClientSideRowModelModule,
   InfiniteRowModelModule,
   CsvExportModule,

   GridChartsModule,
   GridChartsModule,
   SparklinesModule,
   ClipboardModule,
   ColumnsToolPanelModule,
   ExcelExportModule,
   FiltersToolPanelModule,
   MasterDetailModule,
   MenuModule,
   RangeSelectionModule,
   RichSelectModule,
   RowGroupingModule,
   ServerSideRowModelModule,
   SetFilterModule,
   MultiFilterModule,
   SideBarModule,
   StatusBarModule,
   ViewportRowModelModule,
]);
LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061271}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SMARTPULSE_TEKNOLOJI_A.S_(IKITELLI_VD,_VN:7721078553)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{smartpulse}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{smartpulse}_need_to_be_licensed___{smartpulse}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{6_June_2025}____[v3]_[0102]_MTc0OTE2NDQwMDAwMA==c43f3db419119ce170172748b0f7ff39");

// ----------------------------------------------------------------------

export default function App() {
   const [resourceStrings, setResourceStrings] = useState(localeData.en);
   const [searchParams, setSearchParams] = useSearchParams();
   const [locale, setLocale] = useState("en");
   const { search } = useLocation();
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      toast.dismiss();
   }, [location]);

   useEffect(() => {
      Site.Tooltip.Init();

      if ("serviceWorker" in navigator) {
         window.addEventListener("load", function () {
            navigator.serviceWorker
               .register("serviceworker.js?v=83", { scope: "./" })
               .then(() => {
                  Site.BaseUrl = window.location.origin;
                  Site.ServiceWorker.Init();
               })
               .catch((err) => {
                  console.error(`ServiceWorker registration error. ${err}`);
               });
         });
      }
   }, []);

   useEffect(() => {
      if (searchParams.get("locale") != null) {
         const value = searchParams.get("locale") as string;
         if (locale != value) {
            setLocale(value);
            setResourceStrings(localeData[value]);
         }
      }
   }, [search]);

   return (
      <IntlProvider locale={locale} messages={resourceStrings} defaultLocale="en">
         <MotionLazyContainer>
            <ThemeProvider>
               <ThemeSettings>
                  {/* <SocketProvider> */}
                  <ConfirmDialogProvider>
                     <UserCultureProvider>
                        <ProgressBarStyle />
                        <ScrollToTop />
                        <ErrorBoundary
                           FallbackComponent={CommonFallback}
                           onReset={(details: any) => {
                              let redirectRef = "/home?errorRedirect=true";
                              if (details.args[0] === "close") {
                                 redirectRef = "?errorRedirect=true";
                              }
                              navigate(redirectRef);
                           }}
                        // onError={(_error, _componentStack) => {}}
                        >
                           <Router />
                        </ErrorBoundary>
                        <ToastContainer
                           // limit={Constants.toastr.toastrLimit}
                           hideProgressBar={Constants.toastr.hideProgressBar}
                        />
                     </UserCultureProvider>
                  </ConfirmDialogProvider>
                  {/* </SocketProvider> */}
               </ThemeSettings>
            </ThemeProvider>
         </MotionLazyContainer>
      </IntlProvider>
   );
}
