import {
   INotificationListResponse,
   INotificationSettingTypeModel,
   IReadAllResponse,
   IReadNotificationResponse,
   ISettingTypesResponse,
   IUnreadNotificationCountResponse,
   IUpdateUserNotificationSettingsResponse,
   IUserNotificationSettingsResponse,
} from "src/models/notifications.model";
import baseService from "../base.service";

export const getNotifications = async (lastNotificationId: string | null) => {
   const url = "/Notifications/List";
   const response = await baseService.post<INotificationListResponse>(url, lastNotificationId);
   return response.data;
};

export const readNotifications = async (notificaitonId: string) => {
   const url = "/Notifications/ReadNotification";
   const response = await baseService.post<IReadNotificationResponse>(url, { Id: notificaitonId });
   return response.data;
};

export const unreadNotificationCount = async () => {
   const url = "/Notifications/UnreadNotificationCount";
   const response = await baseService.post<IUnreadNotificationCountResponse>(url);
   return response.data;
};

export const readAll = async () => {
   const url = "/Notifications/ReadAll";
   const response = await baseService.post<IReadAllResponse>(url);
   return response.data;
};

export const getUserNotificationSettings = async () => {
   const url = "/Notifications/GetUserNotificationSettings";
   const response = await baseService.post<IUserNotificationSettingsResponse>(url);
   return response.data;
};

export const getSettingTypes = async () => {
   const url = "/Notifications/GetSettingTypes";
   const response = await baseService.post<ISettingTypesResponse>(url);
   return response.data;
};

export const updateUserNotificationSettings = async (request: INotificationSettingTypeModel[]) => {
   const url = "/Notifications/UpdateUserNotificationSettings";
   const response = await baseService.post<IUpdateUserNotificationSettingsResponse>(url, request);
   return response.data;
};
